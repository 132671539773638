import axios from 'axios';

const onStatusChange = (deviceId, payload, headers) => {
	const body = { status: payload }


	return axios.put(`${process.env.REACT_APP_DEVICE_API_URL}v1/devices/status-change/${deviceId}`, body, headers);

}

const OnUnlockDoor = (body) => {
	const payload = {
		"url": `/v1.0/devices/${body.device_id}/door-lock/password-free/open-door`,
		"body": body,
		"method": "post"
	}

	return axios.post(`${process.env.REACT_APP_MOBILE_API_URL}v1/devices/call-tuya-apis`, payload);

}
const getTicket = (body) => {
	const payload = {
		"url": `/v1.0/devices/${body.device_id}/door-lock/password-ticket`,
		"body": body,
		"method": "post"
	}

	return axios.post(`${process.env.REACT_APP_MOBILE_API_URL}v1/devices/call-tuya-apis`, payload);

}

const getDoorLogs = (payload) => {
	const body = payload;

	return axios.post(`${process.env.REACT_APP_MOBILE_API_URL}v1/devices/get-logs`, body);
}

const getDeviceDetails = (deviceId, headers) => {
	// const body = {
	//     "custom_url": `/v1.0/devices/${deviceId}` 
	// }

	// return axios.post(`https://dev-mobile.propertydek.com/api/mobile-app/v1/devices/tuya-test`,body,headers);

	const payload = {
		"url": `/v1.0/devices/${deviceId}`,
		"body": { "device_id": deviceId },
		"method": "get"
	}

	return axios.post(`${process.env.REACT_APP_MOBILE_API_URL}v1/devices/call-tuya-apis`, payload);

}

const getDoorLockKeys = (deviceId) => {
	const payload = {
		"url": `/v1.0/devices/${deviceId}/door-lock/temp-passwords`,
		"body": { "device_id": deviceId },
		"method": "get"
	}

	return axios.post(`${process.env.REACT_APP_MOBILE_API_URL}v1/devices/call-tuya-apis`, payload);
}

const OnUnlockTTLock = (deviceID, headers) => {
	return axios.post(`${process.env.REACT_APP_DEVICE_API_URL}v1/ttlock/unlock/` + deviceID, {}, headers);
}

const SmartDeviceDetailsService = {
	onStatusChange,
	getDeviceDetails,
	getTicket,
	OnUnlockDoor,
	getDoorLogs,
	getDoorLockKeys,
	OnUnlockTTLock
}

export default SmartDeviceDetailsService;