import React from "react";
import styled from "styled-components/macro";
import moment from "moment";
import "./footer.css";
import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper className="FooterMain">
      <div className="footerWrap">
        <Grid container spacing={0}>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem>
                <span className="copyrightText">
                  © {moment().format("YYYY")}{" "}
                  <a
                    className="copyrightTextCompany"
                    href="https://propertydek.com/"
                  >
                    PropertyDek
                  </a>{" "}
                  All rights reserved. v1.41.0
                </span>
              </ListItem>
            </List>
          </Grid>
          <Grid container item xs={12} md={6} justify="flex-end">
            <List>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary="Terms of Service" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    </Wrapper>
  );
}

export default Footer;
