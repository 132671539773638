import { useEffect, useRef, useState } from "react";
import styles from "../dashboard.module.css";
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import { geoJson } from "./GeoJson";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ArrowLeft, ArrowRight, RotateCcw } from "react-feather";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { bbox, pointsWithinPolygon, randomPoint } from "@turf/turf";

const randomPointInPoly = (gJson, no) => {
    let bbox2 = bbox(gJson);

    if (no > 50) {
        no = 50;
    }

    let poin = randomPoint(no, { bbox: bbox2 });
    let poin2 = pointsWithinPolygon(poin, gJson);

    //if (poin2?.features?.length === 0 || poin?.features?.length !== poin2?.features?.length) {
    //return randomPointInPoly(gJson, no);
    //}

    return poin2;
}

const ApartmentDataHeatMap = () => {
    const { getAccessTokenSilently } = useAuth0();
    const mapRef = useRef()
    const mapContainerRef = useRef();
    const [sourceTourPolyJson, setSourceTourPolyJson] = useState(null);
    const [sourceTourPointJson, setSourceTourPointJson] = useState(null);
    const [sourceRequestPolyJson, setSourceRequestPolyJson] = useState(null);
    const [sourceRequestPointJson, setSourceRequestPointJson] = useState(null);
    const [tourData, setTourData] = useState([]);
    const [requestData, setRequestData] = useState([]);
    const [isMapLoad, setIsMapLoad] = useState(false);
    const [selectedMap, setSelectedMap] = useState('tour');
    const [currentFloor, setCurrentFloor] = useState(1);

    useEffect(() => {
        async function fetchData() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-tour-data/1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setTourData(response.data.data);
            }).catch((error) => {
                setTourData([]);
            });
        }
        fetchData();
    }, [getAccessTokenSilently]);


    useEffect(() => {
        async function fetchData() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            await axios.get(process.env.REACT_APP_USER_API_URL + "v1/dashboard/get-request-data/1", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setRequestData(response.data.data);
            }).catch((error) => {
                setRequestData([]);
            });
        }
        fetchData();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        let pointJsonTemp = geoJson;
        let polyJsonTemp = geoJson;
        if (tourData?.length > 0) {
            let apartmentDataTemp = tourData?.map(item => {
                var aptNoInt = item?.apt_no?.replace(/^\D+/g, '');
                return { ...item, apt_no_int: parseInt(aptNoInt) };
            });

            let pointFeaturesNew = [];

            let jsonFeatures2 = polyJsonTemp?.features?.map(item => {
                let newProperties = { is_show: 0 };
                let selectedApt = apartmentDataTemp?.filter(i => i?.apt_no_int === item?.id);
                if (selectedApt?.length > 0) {
                    newProperties = { ...newProperties, floor: selectedApt[0]?.floor, no_of_tour: selectedApt[0]?.no_of_tour, is_show: 1 };
                    if (selectedApt[0]?.no_of_tour > 0) {

                        let pointsTemp = randomPointInPoly(item, selectedApt[0]?.no_of_tour);

                        let pointsFetureTemp = pointsTemp?.features?.map(subItem => {
                            return { ...subItem, properties: { no_of_tour: selectedApt[0]?.no_of_tour, floor: selectedApt[0]?.floor } };
                        });

                        pointFeaturesNew = pointFeaturesNew.concat(pointsFetureTemp);
                    }
                } else if (item?.id === 508) {
                    newProperties = { ...newProperties, floor: 5, no_of_tour: 0, is_show: 1 };
                }

                return { ...item, properties: newProperties };
            });

            pointJsonTemp = { "type": "FeatureCollection", "features": pointFeaturesNew };
            polyJsonTemp = { ...polyJsonTemp, features: jsonFeatures2 };
        }

        setSourceTourPointJson(pointJsonTemp);
        setSourceTourPolyJson(polyJsonTemp);
    }, [tourData]);

    useEffect(() => {
        let pointJsonTemp = geoJson;
        let polyJsonTemp = geoJson;
        if (requestData?.length > 0) {
            let apartmentDataTemp = requestData?.map(item => {
                var aptNoInt = item?.apt_no?.replace(/^\D+/g, '');
                return { ...item, apt_no_int: parseInt(aptNoInt) };
            });

            let pointFeaturesNew = [];

            let jsonFeatures2 = polyJsonTemp?.features?.map(item => {
                let newProperties = { is_show: 0 };
                let selectedApt = apartmentDataTemp?.filter(i => i?.apt_no_int === item?.id);
                if (selectedApt?.length > 0) {
                    newProperties = { ...newProperties, floor: selectedApt[0]?.floor, no_of_request: selectedApt[0]?.no_of_request, is_show: 1 };
                    if (selectedApt[0]?.no_of_request > 0) {

                        let pointsTemp = randomPointInPoly(item, selectedApt[0]?.no_of_request);

                        let pointsFetureTemp = pointsTemp?.features?.map(subItem => {
                            return { ...subItem, properties: { no_of_request: selectedApt[0]?.no_of_request, floor: selectedApt[0]?.floor } };
                        });

                        pointFeaturesNew = pointFeaturesNew.concat(pointsFetureTemp);
                    }
                } else if (item?.id === 508) {
                    newProperties = { ...newProperties, floor: 5, no_of_request: 0, is_show: 1 };
                }

                return { ...item, properties: newProperties };
            });

            pointJsonTemp = { "type": "FeatureCollection", "features": pointFeaturesNew };
            polyJsonTemp = { ...polyJsonTemp, features: jsonFeatures2 };
        }

        setSourceRequestPointJson(pointJsonTemp);
        setSourceRequestPolyJson(polyJsonTemp);
    }, [requestData]);

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiY29tbWh1YiIsImEiOiJjazZxNzFmcXgwaGl2M2twN3h6djV2enBwIn0.5mvVWwnQbxxkLXhCGet8ug';

        if (typeof (mapRef.current) === 'undefined') {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/commhub/cm2u1czn9003c01pq6u5icg3g',
                center: [-83.1003097, 42.3706527],
                zoom: 19.25,
                bearing: 150
            });
        }

        mapRef.current.on('load', () => {
            if (sourceTourPolyJson && sourceTourPointJson) {
                setIsMapLoad(true);
            }
        });

    }, [sourceTourPolyJson, sourceTourPointJson]);

    useEffect(() => {
        if (selectedMap === 'tour') {
            if (isMapLoad && sourceTourPolyJson && sourceTourPointJson) {
                if (mapRef.current.getLayer('floor-heatmap-layer')) {
                    mapRef.current.removeLayer('floor-heatmap-layer');
                }

                if (mapRef.current.getLayer('floor-line-layer')) {
                    mapRef.current.removeLayer('floor-line-layer');
                }

                if (mapRef.current.getSource('floor-source')) {
                    mapRef.current.removeSource('floor-source');
                }

                if (mapRef.current.getSource('floor-source-2')) {
                    mapRef.current.removeSource('floor-source-2');
                }

                mapRef.current.addSource('floor-source', {
                    type: 'geojson',
                    data: sourceTourPointJson
                });

                mapRef.current.addSource('floor-source-2', {
                    type: 'geojson',
                    data: sourceTourPolyJson
                });

                mapRef.current.addLayer({
                    id: 'floor-line-layer',
                    type: 'line',
                    source: 'floor-source-2',
                    paint: {
                        'line-color': '#d2c4a9'
                    },
                    filter: ['==', ['get', 'floor'], currentFloor]
                });

                mapRef.current.addLayer({
                    id: 'floor-heatmap-layer',
                    type: 'heatmap',
                    source: 'floor-source',
                    maxzoom: 21,
                    paint: {
                        'heatmap-weight': {
                            property: 'no_of_tour',
                            type: 'exponential',
                            stops: [
                                [1, 0.1],
                                [2, 0.15],
                                [3, 0.2],
                                [4, 0.25],
                                [5, 0.3],
                                [10, 0.35],
                                [20, 0.4],
                                [30, 0.5],
                                [40, 0.6],
                                [50, 0.7],
                                [100, 0.8],
                            ]
                        },
                        'heatmap-intensity': {
                            stops: [
                                [11, 1],
                                [21, 5]
                            ]
                        },
                        'heatmap-color': [
                            'interpolate',
                            ['linear'],
                            ['heatmap-density'],
                            0,
                            'rgba(33,102,172,0)',
                            0.2,
                            '#857efd',
                            0.4,
                            '#6be73a',
                            0.6,
                            '#ffff00',
                            0.8,
                            '#db9525',
                            1,
                            '#FF0000',
                        ],
                        'heatmap-radius': {
                            stops: [
                                [11, 15],
                                [19, 40],
                                [21, 80],
                            ]
                        },
                        'heatmap-opacity': 0.5
                    },
                    filter: ['==', ['get', 'floor'], currentFloor]
                });
            }
        }

        if (selectedMap === 'request') {
            if (isMapLoad && sourceRequestPolyJson && sourceRequestPointJson) {
                if (mapRef.current.getLayer('floor-heatmap-layer')) {
                    mapRef.current.removeLayer('floor-heatmap-layer');
                }

                if (mapRef.current.getLayer('floor-line-layer')) {
                    mapRef.current.removeLayer('floor-line-layer');
                }

                if (mapRef.current.getSource('floor-source')) {
                    mapRef.current.removeSource('floor-source');
                }

                if (mapRef.current.getSource('floor-source-2')) {
                    mapRef.current.removeSource('floor-source-2');
                }

                mapRef.current.addSource('floor-source', {
                    type: 'geojson',
                    data: sourceRequestPointJson
                });

                mapRef.current.addSource('floor-source-2', {
                    type: 'geojson',
                    data: sourceRequestPolyJson
                });

                mapRef.current.addLayer({
                    id: 'floor-line-layer',
                    type: 'line',
                    source: 'floor-source-2',
                    paint: {
                        'line-color': '#d2c4a9'
                    },
                    filter: ['==', ['get', 'floor'], currentFloor]
                });

                mapRef.current.addLayer({
                    id: 'floor-heatmap-layer',
                    type: 'heatmap',
                    source: 'floor-source',
                    maxzoom: 21,
                    paint: {
                        'heatmap-weight': {
                            property: 'no_of_request',
                            type: 'exponential',
                            stops: [
                                [1, 0.1],
                                [2, 0.15],
                                [3, 0.2],
                                [4, 0.25],
                                [5, 0.3],
                                [10, 0.35],
                                [20, 0.4],
                                [30, 0.5],
                                [40, 0.6],
                                [50, 0.7],
                                [100, 0.8],
                            ]
                        },
                        'heatmap-intensity': {
                            stops: [
                                [11, 1],
                                [21, 5]
                            ]
                        },
                        'heatmap-color': [
                            'interpolate',
                            ['linear'],
                            ['heatmap-density'],
                            0,
                            'rgba(33,102,172,0)',
                            0.2,
                            '#857efd',
                            0.4,
                            '#6be73a',
                            0.6,
                            '#ffff00',
                            0.8,
                            '#db9525',
                            1,
                            '#FF0000',
                        ],
                        'heatmap-radius': {
                            stops: [
                                [11, 15],
                                [19, 40],
                                [21, 80],
                            ]
                        },
                        'heatmap-opacity': 0.5
                    },
                    filter: ['==', ['get', 'floor'], currentFloor]
                });
            }
        }

    }, [isMapLoad, sourceTourPolyJson, sourceTourPointJson, sourceRequestPolyJson, sourceRequestPointJson, currentFloor, selectedMap]);

    const reloadMap = () => {
        if (mapRef.current) {
            mapRef.current.setZoom(19.25);
            mapRef.current.setCenter([-83.1003097, 42.3706527]);
            mapRef.current.setBearing(150);
        }
    }

    const goToPrev = () => {
        if (mapRef.current) {
            mapRef.current.setZoom(19.25);
            mapRef.current.setCenter([-83.1003097, 42.3706527]);
            mapRef.current.setBearing(150);

            setCurrentFloor(prev => {
                return prev - 1;
            });
        }
    }

    const goToNext = () => {
        if (mapRef.current) {
            mapRef.current.setZoom(19.25);
            mapRef.current.setCenter([-83.1003097, 42.3706527]);
            mapRef.current.setBearing(150);

            setCurrentFloor(prev => {
                return prev + 1;
            });
        }
    }

    return (
        <div className={`${styles.IconCardDivTwo}`}>
            <div className={`${styles.IconCardFullDiv}`}>
                <div className={`${styles.IfnoCard}`}>
                    <div className={`${styles.BatteryHead3D}`}>
                        <div className={`${styles.FormGroupSelect} FormGroup`}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Select Type"
                                    value={selectedMap}
                                    onChange={(e) => {
                                        setSelectedMap(e.target.value);
                                        setCurrentFloor(1);
                                    }}
                                >
                                    <MenuItem value={'tour'}>Tour Data</MenuItem>
                                    <MenuItem value={'request'}>Maintenance Request Data</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <button onClick={(e) => reloadMap()} className={`${styles.ReloadMapBU}`}><RotateCcw />Reset</button>
                    </div>
                    <div className={`${styles.InsideCard}`}>
                        <div className={`${styles.Map3D}`}>
                            <p className={`${styles.FloorTitle}`}>Floor {currentFloor}</p>
                            <div className={`${styles.FloorWiseBU}`}>
                                {currentFloor > 1 && <button className={`${styles.Actions} ${styles.PreBU}`} onClick={(e) => goToPrev()} title={"Click to see floor " + (currentFloor - 1)}><ArrowLeft /></button>}
                                {currentFloor < 5 && <button className={`${styles.Actions} ${styles.NextBU}`} onClick={(e) => goToNext()} title={"Click to see floor " + (currentFloor + 1)} style={{ left: '65px' }}><ArrowRight /></button>}
                            </div>
                            <div className={`${styles.Map3SCanvas}`}>
                                <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApartmentDataHeatMap;