import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./leases.module.css";
import { Typography } from "@material-ui/core";
import { Home, Search, Plus, X, ArrowRight, Filter, ArrowLeft } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import axios from "axios";
import { TableLoader } from "../../components/LoaderC";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select, Snackbar, Slide, Dialog, DialogTitle, DialogContent, TablePagination } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddLeaseForm from "./components/AddLeaseForm";

const Leases = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [allData, setAllData] = useState([]);
  const [filterredData, setFilterredData] = useState([]);
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState({ searchKey: "", property: "", apartment: "", status: "" });
  const [propertyList, setPropertyList] = useState([]);
  const [apartmentList, setApartmentList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [perPage, setPerPage] = useState(8);


  useEffect(() => {
    async function getLeases() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_PAYMENT_API_URL + "v1/leases", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setAllData(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
      });
    }
    getLeases();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    async function getProperties() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios(process.env.REACT_APP_PAYMENT_API_URL + "v1/property-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);
        setPropertyList([{ value: "all", label: "Any", apartments: [] }].concat(response.data.data));

        if (localStorage.getItem("leaseStatusyFilterData")) {
          let statusFilter = localStorage.getItem("leaseStatusyFilterData");
          localStorage.removeItem("leaseStatusyFilterData");

          setFilterData(prev => { return { ...prev, status: statusFilter } });
        }

      }).catch((error) => {
        setContentLoading(false);
      });
    }
    getProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let filterredDataTemp = allData;

    if (filterData?.searchKey !== "") {
      let inputVal = filterData?.searchKey;
      inputVal = inputVal.toLowerCase();
      filterredDataTemp = filterredDataTemp.filter((item) => item.tenant_name.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_no.toLowerCase().includes(inputVal) || item.formatted_amount.toLowerCase().includes(inputVal) || item.formatted_rent.toLowerCase().includes(inputVal));
    }

    if (filterData?.property !== "" && filterData?.property !== "all") {
      filterredDataTemp = filterredDataTemp.filter((i) => i.property_id === filterData.property);
    }

    if (filterData?.apartment !== "" && filterData?.apartment !== "all") {
      filterredDataTemp = filterredDataTemp.filter((i) => i.apt_id === filterData.apartment);
    }

    if (filterData?.status !== "" && filterData?.status !== "all") {
      filterredDataTemp = filterredDataTemp.filter((i) => i.lease_status.indexOf(parseInt(filterData.status)) > -1);
    }

    setFilterredData(filterredDataTemp);
  }, [allData, filterData]);

  const onSubmitLocal = (data) => {
    setAllData(prev => {
      let tempList = prev.filter(i => i?.tenant_id !== data?.tenant_id);
      return [data].concat(tempList);
    });
  }

  return (<React.Fragment>
    {loading && <Loader />}
    <Helmet title="Leases" />
    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Leases</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Payment</li>
          <li className={`${styles.breadcrumbItem} ${styles.active}`}>Leases</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          <div className="control-pane">

            {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

            <div className={`${styles.Row}`}>

              {!contentLoading && <>
                <div className={`${styles.SearchFilterSec}`}>
                  <div className={`${styles.SearchCon}`}>
                    <div className={`${styles.SearchConSec}`}>
                      <Search />
                      <input type="text" placeholder="Search" onChange={(e) => setFilterData((prev) => { return { ...prev, searchKey: e.target.value }; })} />
                    </div>
                  </div>

                  <div className={`${styles.FilterSec}`}>
                    <div className={filterOpen ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                      <p className={`${styles.FilterTitle}`}>
                        Filters
                        <span onClick={(e) => setFilterOpen(false)}><ArrowRight /></span>
                      </p>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width220} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Property</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Property"
                            value={filterData?.property}
                            onChange={(e) => {
                              setFilterData((prev) => { return { ...prev, property: e.target.value }; });

                              if (e.target.value !== "all") {
                                let propertyTemp = propertyList.filter((i) => i.value === e.target.value);
                                setApartmentList([{ value: "all", label: "Any" }].concat(propertyTemp[0]?.apartments));
                              } else {
                                setApartmentList([{ value: "all", label: "Any" }]);
                              }
                            }}
                          >
                            {propertyList?.map((i) => {
                              return (<MenuItem value={i.value}>{i.label}</MenuItem>);
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width160} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Arartment</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Arartment"
                            value={filterData?.apartment}
                            onChange={(e) => { setFilterData((prev) => { return { ...prev, apartment: e.target.value }; }); }}
                          >
                            {apartmentList?.map((i) => {
                              return (<MenuItem value={i.value}>{i.label}</MenuItem>);
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width160} CusSelectFilter Filter`}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Lease Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Lease Status"
                            value={filterData?.status}
                            onChange={(e) => { setFilterData((prev) => { return { ...prev, status: e.target.value }; }); }}
                          >
                            <MenuItem value={"all"}>Any</MenuItem>
                            <MenuItem value={0}>Not Verified</MenuItem>
                            <MenuItem value={1}>Verified</MenuItem>
                            <MenuItem value={2}>Not Signed</MenuItem>
                            <MenuItem value={3}>Tenant Signed</MenuItem>
                            <MenuItem value={4}>Signed</MenuItem>
                            <MenuItem value={5}>Expire Soon</MenuItem>
                            <MenuItem value={6}>Expired</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <Link className={`${styles.FilterBu}`} onClick={(e) => setFilterOpen(true)}>
                      <Filter />
                    </Link>
                    {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("lease:cud") > -1 && (<Link className={`${styles.AddNoteBu}`} onClick={(e) => setShowAddForm(true)}>
                      <Plus /> <span>Lease</span>
                    </Link>)}
                  </div>
                </div>

                <div className={`${styles.NotesRow}`}>

                  {filterredData.map((item, index) => {
                    return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link key={item?.id} className={`${styles.ProspectCard}`} to={"/leases/details/" + item.tenant_id} >
                      <div className={`${styles.ProspectCardUnder}`}>
                        <div className={`${styles.ProspectCardHeadSection}`}>
                          <div className={`${styles.ProsIMGSec}`}>
                            {item?.tenant_image !== "" ? <img src={item?.tenant_image} className={`${styles.ProspectCardImg}`} alt="" /> : <p>{item?.tenant_initial}</p>}
                          </div>

                          <div className={`${styles.HeadConSec}`}>
                            <div className={`${styles.prospectNameActionSec}`}>
                              <p className={`${styles.ProsUserName}`}>{item.tenant_name} {item?.no_of_tenant > 1 && '+' + (item?.no_of_tenant - 1)}</p>
                            </div>
                            <p className={`${styles.ProsUserName}`}>
                              {item.lease_status.indexOf(1) > -1 && item.lease_status.indexOf(6) === -1 && (<span className={`${styles.TagDiv} ${styles.Success}`}>Verified</span>)}
                              {item.lease_status.indexOf(0) > -1 && item.lease_status.indexOf(6) === -1 && (<span className={`${styles.TagDiv} ${styles.Purple}`}>Not Verified</span>)}
                              {item.lease_status.indexOf(4) > -1 && item.lease_status.indexOf(6) === -1 && (<span className={`${styles.TagDiv} ${styles.Success}`}>Signed</span>)}
                              {item.lease_status.indexOf(3) > -1 && item.lease_status.indexOf(6) === -1 && (<span className={`${styles.TagDiv} ${styles.Success}`}>Tenant Signed</span>)}
                              {item.lease_status.indexOf(2) > -1 && item.lease_status.indexOf(6) === -1 && (<span className={`${styles.TagDiv} ${styles.Purple}`}>Not Signed</span>)}
                              {item.lease_status.indexOf(5) > -1 && (<span className={`${styles.TagDiv} ${styles.Yellow}`}>Expire Soon</span>)}
                              {item.lease_status.indexOf(6) > -1 && (<span className={`${styles.TagDiv} ${styles.Error}`}>Expired</span>)}
                            </p>
                          </div>
                        </div>
                        <div className={`${styles.ProspectCardBodySec}`}>
                          <ul>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/bill-icon.svg" alt="" />Rent :</p>
                              <p className={`${styles.Content}`}>${item.formatted_rent}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/credit-icon.svg" alt="" />Sec. Deposit :</p>
                              <p className={`${styles.Content}`}>${item.formatted_amount}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/calendar.svg" alt="" />Lease Term :</p>
                              <p className={`${styles.Content}`}>{moment(item.start_date).format("L")} - {moment(item.end_date).format("L")}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/home.svg" alt="" />Property :</p>
                              <p className={`${styles.Content}`}>{item.property_name}</p>
                            </li>
                            <li>
                              <p className={`${styles.Title}`}><img src="/static/img/rooms.svg" alt="" />Unit :</p>
                              <p className={`${styles.Content}`}>{item.apt_no}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Link>) : null;
                  })}

                  {filterredData.length > 0 && (<div className="Pagination TablePaginationNew List">
                    <TablePagination
                      count={filterredData.length}
                      color="primary"
                      page={page}
                      rowsPerPage={perPage}
                      onPageChange={(e, v) => setPage(v)}
                      rowsPerPageOptions={[
                        { label: '4', value: 4 },
                        { label: '8', value: 8 },
                        { label: '16', value: 16 },
                        { label: 'All', value: -1 },
                      ]}
                      onRowsPerPageChange={(e) => {
                        setPage(0);
                        setPerPage(e.target.value);
                      }}
                      labelRowsPerPage={'Leases per Page:'}
                    />
                  </div>)}

                  {filterredData.length === 0 && (<div className={`${styles.NoDataMain}`}>
                    <div className={`${styles.NoDataIMG}`}>
                      <img src="/static/img/no-lease.png" alt="No Data" />
                    </div>
                    <p className={`${styles.NoDataText}`}>Unfortunately!</p>
                    <p className={`${styles.NoDataTextSub}`}>we couldn't find any leases at this time.</p>
                  </div>)}

                </div>
              </>}

            </div>

          </div>
        </div>
      </div>
    </div>

    <Dialog
      fullWidth
      maxWidth="sm"
      open={showAddForm}
      onClose={(e) => setShowAddForm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddForm(false)}><ArrowLeft /></button>
        Add Lease
        <button onClick={(e) => setShowAddForm(false)}><X /></button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <AddLeaseForm setShowAddForm={setShowAddForm.bind(this)} setLoading={setLoading.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onSubmitLocal.bind(this)} />
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Footer />
  </React.Fragment >);
}

export default withAuthenticationRequired(Leases, {
  onRedirecting: () => <Loader />,
});