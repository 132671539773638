import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import styles from "../prospectdetails.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import dayjs from "dayjs";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { communicationChatReload } from "../../../redux/actions/communicationChatRloadAction";

const phoneRegExp = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
const schema = yup.object().shape({
  phone: yup.string().required("This field is Required").matches(phoneRegExp, "Invalid phone number"),
});

const PhoneField = React.forwardRef((props, ref) => {
  return (<NumberFormat
    customInput={TextField}
    prefix=""
    displayType="input"
    type="tel"
    format={"(###) ###-####"}
    {...props}
  />);
});

const TenDigitField = React.forwardRef((props, ref) => {
  return (<NumberFormat
    customInput={TextField}
    prefix=""
    displayType="input"
    type="text"
    thousandSeparator={false}
    decimalScale={0}
    {...props}
  />);
});

const MoneyField = React.forwardRef((props, ref) => {
  return (<NumberFormat
    customInput={TextField}
    prefix=""
    displayType="input"
    type="text"
    thousandSeparator={true}
    allowNegative={false}
    decimalScale={0}
    {...props}
  />);
});

const MoneyFieldCurrentRent = React.forwardRef((props, ref) => {
  return (<NumberFormat
    customInput={TextField}
    prefix=""
    displayType="input"
    type="text"
    thousandSeparator={true}
    allowNegative={false}
    decimalScale={2}
    fixedDecimalScale
    {...props}
  />);
});

const EditPropsectNew = ({ setEditModalOpen, setLoading, setSnackbarMsg, setSnackbarOpen, prospectData, updateApplicantData }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [apartments, setApartments] = useState([]);
  const [incomeType, setIncomeType] = useState('');
  const dispatch = useDispatch();

  const { handleSubmit, control, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      property_id: "",
      apt_id: "",
      ever_had_bankruptcy: "",
      bedrooms: "",
      bathrooms: "",
      do_you_smoke: "",
      move_in_date: null,
      income_type: "",
      income_per_year: "",
      voucher_amount: "",
    },
  });

  useEffect(() => {
    if (prospectData) {
      setIncomeType(prospectData?.income_type);
      setValue("name", prospectData?.full_name);
      setValue("email", prospectData?.email);
      setValue("phone", prospectData?.phone);
      setValue("property_id", prospectData?.property_id);
      setValue("apt_id", prospectData?.apt_id);
      setValue("credit_score", prospectData?.credit_score);
      setValue("income_type", prospectData?.income_type);




      if (prospectData?.ever_had_bankruptcy !== "") {
        setValue("ever_had_bankruptcy", prospectData?.ever_had_bankruptcy);
      }
      if (prospectData?.bedrooms !== "") {
        setValue("bedrooms", prospectData?.bedrooms);
      }
      if (prospectData?.bathrooms !== "" && prospectData?.bathrooms > 0) {
        setValue("bathrooms", prospectData?.bathrooms);
      }
      setValue("ever_case_filed", prospectData?.ever_case_filed);
      setValue("have_pets", prospectData?.have_pets);
      setValue("current_rent", prospectData?.current_rent);

      if (prospectData?.move_in_date !== "") {
        setValue("move_in_date", dayjs(prospectData?.move_in_date));
      }

      if (prospectData?.do_you_smoke !== "") {
        setValue("do_you_smoke", prospectData?.do_you_smoke);
      }

      setTimeout(() => {
        if (prospectData?.income_type === 'Salary') {
          setValue("income_per_year", prospectData?.income_per_year);
        }
        if (prospectData?.income_type === 'Voucher') {
          setValue("voucher_amount", prospectData?.voucher_amount);
        }
      }, 500);
    }
  }, [prospectData, setValue]);

  useEffect(() => {
    async function fetchData() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/apartments/" + prospectData?.property_id, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setApartments(response?.data?.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
    if (prospectData?.property_id) {
      fetchData();
    }
  }, [prospectData?.property_id, prospectData?.apt_id, getAccessTokenSilently]);

  const onSubmit = async (data) => {
    let postData = {
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      apt_id: data?.apt_id,
      credit_score: data?.credit_score,
      current_rent: data?.current_rent.replace(/,/g, ""),
      ever_case_filed: data?.ever_case_filed,
      have_pets: data?.have_pets,
      ever_had_bankruptcy: data?.ever_had_bankruptcy,
      do_you_smoke: data?.do_you_smoke,
      bedrooms: data?.bedrooms,
      bathrooms: data?.bathrooms,
      income_type: data?.income_type,
    };

    if (data?.income_per_year && typeof (data?.income_per_year) !== 'undefined') {
      postData = { ...postData, income_per_year: data?.income_per_year.replace(/,/g, "") };
    }

    if (data?.voucher_amount && typeof (data?.voucher_amount) !== 'undefined') {
      postData = { ...postData, voucher_amount: data?.voucher_amount };
    }

    if (typeof data?.move_in_date !== "undefined" && data?.move_in_date) {
      postData = { ...postData, move_in_date: dayjs(data?.move_in_date).format("YYYY-MM-DD") };
    }

    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/" + prospectData?.id, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);
      setEditModalOpen(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);
      dispatch(communicationChatReload(true));

      updateApplicantData(response.data.data);

      let logData = {
        title: "Prospect is updated",
        description: [
          "Name: " + response.data.data?.full_name,
          "Email: " + response.data.data?.email,
          "Phone: " + response.data.data?.phone,
          "Property: " + response.data.data?.property_name,
          "Apartment: " + response.data.data?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  return (<div className={`${styles.ProfileCardEditSec}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`${styles.ProfileIMGSec}`}>
        <div className={`${styles.ProfileIMGCard}`}>
          <div className={`${styles.ProfileIMG}`}>
            {prospectData?.initial !== "?" && (<p style={{ width: "95px", height: "95px", display: "flex", alignItems: "center", justifyContent: "center", background: "#fbf7fc", margin: 0, borderRadius: "50%", position: "relative", zIndex: 2, fontSize: "26px", fontWeight: "600", lineHeight: "normal", color: "#502688", letterSpacing: "1px", border: "2px solid #fff" }}>{prospectData?.initial}</p>)}
            {prospectData?.initial === "?" && (<img src="/static/img/DefaultLogo.png" className={`${styles.defaultImg}`} alt="" />)}
          </div>
        </div>
      </div>
      <div className={`${styles.ProfileEditFormSec} ${styles.ProfileEditFirstSec}`}>
        <div className={`${styles.EditFormFullSec} FormGroup`}>
          <Controller
            name={`name`}
            control={control}
            render={(field) => (
              <TextField {...field} label="Name" variant="outlined" />
            )}
          />
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`email`}
            control={control}
            render={(field) => (
              <TextField {...field} label="Email" variant="outlined" />
            )}
          />
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`phone`}
            control={control}
            render={(field) => (
              <PhoneField {...field} label="Phone*" variant="outlined" />
            )}
          />
          {errors?.phone && (<p className={`${styles.ErrorM}`}>{errors?.phone?.message}</p>)}
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Property*</InputLabel>
            <Controller
              name="property_id"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Property*"}
                  disabled
                >
                  <MenuItem value={prospectData?.property_id}>
                    {prospectData?.property_name}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Apartment</InputLabel>
            <Controller
              name="apt_id"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Apartment"}
                >
                  {apartments?.map((item) => {
                    return (<MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>);
                  })}
                </Select>
              )}
            />
          </FormControl>
        </div>
      </div>
      <div className={`${styles.ProfileEditFormSec}`}>
        <p className={`${styles.ProfileEditSubTT}`}>Score Card</p>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`credit_score`}
            control={control}
            render={(field) => (
              <TenDigitField
                {...field}
                label="Credit Score"
                variant="outlined"
                format="###"
                disabled={prospectData?.is_credit_score_verify === 1}
              />
            )}
          />
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Income Type</InputLabel>
            <Controller
              name="income_type"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Income Type"}
                  onChange={(e) => {
                    field.onChange(e);
                    console.log(e.target.value);
                    setIncomeType(e.target.value);
                  }}
                >
                  <MenuItem value={'Salary'}>Salary</MenuItem>
                  <MenuItem value={'Voucher'}>Voucher</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
        {incomeType === 'Salary' && <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`income_per_year`}
            control={control}
            render={(field) => (
              <MoneyField {...field} label="Amount" variant="outlined" />
            )}
          />
        </div>}
        {incomeType === 'Voucher' && <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`voucher_amount`}
            control={control}
            render={(field) => (
              <TextField {...field} label="Amount" variant="outlined" />
            )}
          />
        </div>}
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Bankruptcy</InputLabel>
            <Controller
              name="ever_had_bankruptcy"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Bankruptcy"}
                >
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`ever_case_filed`}
            control={control}
            render={(field) => (
              <TenDigitField
                {...field}
                label="Housing Court Case"
                variant="outlined"
                format="##"
              />
            )}
          />
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`current_rent`}
            control={control}
            render={(field) => (
              <MoneyFieldCurrentRent
                {...field}
                label="Current Monthly Rent"
                variant="outlined"
              />
            )}
          />
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              className={`${styles.formControl}`}
              name="move_in_date"
              control={control}
              render={(fields) => (
                <DatePicker
                  {...fields}
                  label="Move-In Date"
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Bedrooms</InputLabel>
            <Controller
              name="bedrooms"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Bedrooms"}
                >
                  <MenuItem value={"Studio"}>Studio</MenuItem>
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Bathroom</InputLabel>
            <Controller
              name="bathrooms"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Bathrooms"}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <Controller
            name={`have_pets`}
            control={control}
            render={(field) => (
              <TenDigitField
                {...field}
                label="Pets"
                variant="outlined"
                format="##"
              />
            )}
          />
        </div>
        <div className={`${styles.EditFormHalfSec} FormGroup`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Smoke</InputLabel>
            <Controller
              name="do_you_smoke"
              control={control}
              render={(field) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={"Smoke"}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
      </div>
      <div className={`${styles.ButtonArea}`}>
        <button className={`${styles.ButtonVoiletLine}`} type="button" onClick={(e) => setEditModalOpen(false)}>Cancel</button>
        <button className={`${styles.ButtonVoiletSolid}`} type="submit">Save</button>
      </div>
    </form>
  </div>);
};

export default EditPropsectNew;