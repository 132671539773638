import styles from "../article-category.module.css";
import { Button, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../../components/AddLogs";

const schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
});


const ArticleCategoryAdd = ({ setShowAddForm, setLoading, onAdd, setSnackbarMsg, setSnackbarOpen, isEdit, selectecId }) => {
    const { getAccessTokenSilently } = useAuth0();

    const { handleSubmit, control, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            title: ''
        }
    });

    const onSubmit = async (data) => {
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_RESOURCE_API_URL + "v1/article-categories", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            onAdd(response.data.data);
            setShowAddForm(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let logData = {
                'title': 'New KB Category is added',
                'description': [
                    'Title: ' + response.data.data?.title
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onUpdate = async (data) => {
        console.log(data);
    }

    return (<div className={`${styles.ModalFormGroup}`}>
        <form onSubmit={handleSubmit(isEdit ? onUpdate : onSubmit)}>
            <div className={`${styles.AddLeaseSection}`}>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <Controller
                            name={`title`}
                            control={control}
                            render={(field) => (<TextField {...field} id="outlined-basic" label="Category Title*" variant="outlined" />)}
                        />
                        {errors.title && (<p className={`${styles.ErrorM}`}>{errors?.title?.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.ButtonAction}`}>
                    <Button className="CancelPopupBU" onClick={(e) => setShowAddForm(false)}>Cancel</Button>
                    <Button className="SubmitPopupBU" type="submit">{isEdit ? 'Update' : 'Add'}</Button>
                </div>
            </div>
        </form>
    </div>);
}

export default ArticleCategoryAdd;