import styles from "../alerts.module.css";
import dayjs from "dayjs";
import { Box, Button, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../components/AddLogs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
    start_date: yup.date().required("This field is Required"),
    end_date: yup.date().required("This field is Required"),
    property_ids: yup.array().required("This field is Required").nullable(),
    description: yup.string().required("This field is Required"),
});


const AlertAdd = ({ setShowAddForm, setLoading, onAdd, setSnackbarMsg, setSnackbarOpen, isEdit, selectecId }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [propertyList, setPropertyList] = useState([]);

    const { handleSubmit, control, errors, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            title: '',
            property_ids: []
        }
    });

    useEffect(() => {
        const getPropertyList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/property-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setPropertyList(response.data.data);
            }).catch(() => {
                setPropertyList([]);
            });
        }

        getPropertyList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const getDetails = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/alerts/" + selectecId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let responseData = response.data.data;

                setValue('title', responseData?.title);
                setValue('start_date', dayjs(responseData?.start_date));
                setValue('end_date', dayjs(responseData?.end_date));
                setValue('property_ids', responseData?.property_id_arr);
                setValue('description', responseData?.description);
                setSelectedProperties(responseData?.property_id_arr);
            });
        }

        if (isEdit) {
            getDetails();
        }
    }, [isEdit, selectecId, getAccessTokenSilently, setValue]);

    const onSubmit = async (data) => {
        let postData = { title: data?.title, description: data?.description, start_date: dayjs(data?.start_date).format('YYYY-MM-DD'), end_date: dayjs(data?.end_date).format('YYYY-MM-DD') };

        if (data?.property_ids?.length) {
            postData = { ...postData, properties: data?.property_ids };
        }

        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_RESOURCE_API_URL + "v1/alerts", postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            onAdd(response.data.data);
            setShowAddForm(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let logData = {
                'title': 'New comminty board alert is added',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Valid On: ' + dayjs(response.data.data?.start_date).format('MMM D, YYYY') + ' to ' + dayjs(response.data.data?.end_date).format('MMM D, YYYY'),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onUpdate = async (data) => {
        let postData = { title: data?.title, description: data?.description, start_date: dayjs(data?.start_date).format('YYYY-MM-DD'), end_date: dayjs(data?.end_date).format('YYYY-MM-DD') };

        if (data?.property_ids?.length) {
            postData = { ...postData, properties: data?.property_ids };
        }

        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_RESOURCE_API_URL + "v1/alerts/" + selectecId, postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            onAdd(response.data.data);
            setShowAddForm(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let logData = {
                'title': 'Comminty board alert is updated',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Valid On: ' + dayjs(response.data.data?.start_date).format('MMM D, YYYY') + ' to ' + dayjs(response.data.data?.end_date).format('MMM D, YYYY'),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div className={`${styles.ModalFormGroup}`}>
        <form onSubmit={handleSubmit(isEdit ? onUpdate : onSubmit)}>
            <div className={`${styles.AddLeaseSection}`}>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <Controller
                            name={`title`}
                            control={control}
                            render={(field) => (<TextField {...field} id="outlined-basic" label="Alert Title*" variant="outlined" />)}
                        />
                        {errors.title && (<p className={`${styles.ErrorM}`}>{errors?.title?.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.DatepickerRow}`}>
                        <div className={`${styles.HalfSecHalf} FormGroup`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="start_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="Start Date*"
                                            renderInput={(params) => (<TextField {...params} />)}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            {errors.start_date && (<p className={`${styles.ErrorM}`}>{errors.start_date.message}</p>)}
                        </div>
                        <div className={`${styles.HalfSecHalf} FormGroup`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="end_date"
                                    control={control}
                                    render={(field) => (
                                        <DatePicker
                                            {...field}
                                            label="End Date*"
                                            renderInput={(params) => (<TextField {...params} />)}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            {errors.end_date && (<p className={`${styles.ErrorM}`}>{errors.end_date.message}</p>)}
                        </div>
                    </div>
                </div>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Property*</InputLabel>
                            <Controller
                                name="property_ids"
                                control={control}
                                render={(field) => (
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={selectedProperties}
                                        onChange={(e) => {
                                            setSelectedProperties(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
                                            setValue("property_ids", e.target.value);
                                        }}
                                        input={<OutlinedInput id="select-multiple-chip" label="Property*" />}
                                        renderValue={(selected) => {
                                            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {propertyList.map((value) => {
                                                    return selected?.indexOf(value?.id) > -1 ? <Chip key={value?.id} label={value?.name} /> : null;
                                                })}
                                            </Box>
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {propertyList.map((item) => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                <Checkbox checked={selectedProperties.indexOf(item.id) > -1} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        {errors.property_ids && (<p className={`${styles.ErrorM}`}>{errors.property_ids.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.FormGroupTextareaFull}`}>
                    <label className={`${styles.FilterLabel}`}>Description<span className={`${styles.errorSpan}`}>*</span></label>
                    <Controller
                        name="description"
                        control={control}
                        render={(field) => (
                            <Editor
                                className={`${styles.formControlTextArea}`}
                                apiKey={"z3r7z5rrb92qgt55caemf3mrz1kdxuckuq25g9u3syc75r7u"}
                                value={field?.value}
                                init={{
                                    height: 400,
                                    menubar: false,
                                    plugins: ["lists"],
                                    toolbar: "undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | ltr rtl | code",
                                }}
                                onEditorChange={(e) => field.onChange(e)}
                            />
                        )}
                    />
                    {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
                </div>
                <div className={`${styles.ButtonAction}`}>
                    <Button className="CancelPopupBU" onClick={(e) => setShowAddForm(false)}>Cancel</Button>
                    <Button className="SubmitPopupBU" type="submit">{isEdit ? 'Update' : 'Add'}</Button>
                </div>
            </div>
        </form>
    </div>);
}

export default AlertAdd;