import styles from "../article-category.module.css";
import { MoreVertical, Trash2 } from "react-feather";
import { Dropdown } from "react-bootstrap";
import dayjs from "dayjs";

const ArticleCategoryCard = ({ item, setShowdeletePopup, setSelectecId, setShowEditForm }) => {

    return (<div className={`${styles.ProspectCard}`}>
        <div className={`${styles.ProspectCardUnder}`}>
            <div className={`${styles.ProspectCardHeadSection}`}>
                <Dropdown className={`${styles.ActionLeaseBUSec}`}>
                    <Dropdown.Toggle className={`${styles.ActionsLeaseBU} ActionsBU`} id="dropdown-basic"><MoreVertical /></Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                        {/*<Dropdown.Item className={`${styles.EditActionsBUListItem}`}><Edit /> Edit</Dropdown.Item>*/}
                        <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setShowdeletePopup(true); setSelectecId(item?.id); }}><Trash2 /> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className={`${styles.ProsIMGSec}`}>
                    <img src="/static/img/category-kb.svg" className={`${styles.ProspectCardImg}`} alt="" />
                </div>
                <div className={`${styles.HeadConSec}`}>
                    <p className={`${styles.ProsUserName}`}>
                        <span className={`${styles.UserName}`}>{item?.title}</span>
                    </p>
                    <p className={`${styles.ProsUserName}`}>&nbsp;</p>
                </div>
            </div>
            <div className={`${styles.TemplateCardFoot}`}>
                <p className={`${styles.LeaseConDate}`}>{dayjs(item?.created_at).fromNow()}</p>
            </div>
        </div>
    </div>)
}

export default ArticleCategoryCard;