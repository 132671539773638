import React, { useEffect, useState } from "react";
import styles from "./smartdevices.module.css";
import { Link } from "react-router-dom";
import { Home, Search, Plus, X, ArrowRight, Filter, Smartphone, Key } from "react-feather";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import axios from "axios";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import PubNubChannel from "./components/pubnub/pubnub-channel";
import { Typography, FormControl, InputLabel, MenuItem, Select, Snackbar, Slide, IconButton, Dialog, Tooltip, TablePagination } from "@mui/material";
import moment from "moment";
import AddDeviceFormNew from "./AddComponent/AddDeviceFormNew";
import AddLockFormNew from "./AddComponent/AddLockFormNew";
import { Dropdown } from "react-bootstrap";

let statusList = [
  { value: "all", label: "All" },
  { value: 1, label: "Online" },
  { value: 0, label: "Offline" },
];
let locationtList = [
  { value: "all", label: "All" },
  { value: "Bathroom", label: "Bathroom" },
  { value: "Bedroom", label: "Bedroom" },
  { value: "Bedroom Closet", label: "Bedroom Closet" },
  { value: "Living Room", label: "Living Room" },
  { value: "Living Room Closet", label: "Living Room Closet" },
];
let productList = [
  { value: "all", label: "All" },
  { value: "Propertydek-Door Lock", label: "Propertydek-Door Lock" },
  { value: "Propertydek-Light", label: "Propertydek-Light" },
  { value: "Propertydek-Thermostat", label: "Propertydek-Thermostat" },
  { value: "Propertydek-Lock Gateway", label: "Propertydek-Lock Gateway" },
  /* { value: "Propertydek-LED Light", label: "Propertydek-LED Light" },
   
   { value: "Propertydek-Water Meter", label: "Propertydek-Water Meter" },*/
];

const SmartDevices = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [contentLoading, setContentLoading] = useState(true);
  const [deviceAllList, setDeviceAllList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [apartmentList, setApartmentList] = useState([]);
  const [filterData, setFilterData] = useState({ searchKey: "", product: "", property: "", location: "", status: "", apartment: "" });
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [showAddFormNew, setShowAddFormNew] = useState(false);
  const [showAddFormNew2, setShowAddFormNew2] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [perPage, setPerPage] = useState(8);


  useEffect(() => {
    const fetchAllProperties = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      axios(process.env.REACT_APP_DEVICE_API_URL + "v1/properties", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        let propertyData = response.data.data;

        propertyData = [{ value: "all", label: "All", apartments: [] }].concat(
          propertyData
        );
        setPropertyList(propertyData);

        if (localStorage.getItem("propertyFilterData")) {
          let filterPropertyId = localStorage.getItem("propertyFilterData");
          localStorage.removeItem("propertyFilterData");

          setFilterData(prev => { return { ...prev, property: parseInt(filterPropertyId) } });

          let apartmentListTemp = [];
          propertyData?.map(i => {
            if (i.value === parseInt(filterPropertyId)) {
              apartmentListTemp = i.apartments;
            }
            return null;
          });

          apartmentListTemp = [{ value: 'all', label: 'All', apartments: [] }].concat(apartmentListTemp);
          setApartmentList(apartmentListTemp);
        }

        if (localStorage.getItem("aptFilterData")) {
          let aptFilterId = localStorage.getItem("aptFilterData");
          localStorage.removeItem("aptFilterData");

          setFilterData(prev => { return { ...prev, apartment: parseInt(aptFilterId) } });
        }

        if (localStorage.getItem("productFilterData")) {
          let productFilter = localStorage.getItem("productFilterData");
          localStorage.removeItem("productFilterData");

          setFilterData(prev => { return { ...prev, product: productFilter } });
        }

        if (localStorage.getItem("statusFilterData")) {
          let statusFilter = localStorage.getItem("statusFilterData");
          localStorage.removeItem("statusFilterData");

          setFilterData(prev => { return { ...prev, status: statusFilter } });
        }
      });
    };
    fetchAllProperties();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchDevices = async () => {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      axios(process.env.REACT_APP_DEVICE_API_URL + "v1/devices", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setContentLoading(false);

        setDeviceAllList(response.data.data);
      }).catch((error) => {
        setContentLoading(false);
      });
    };
    fetchDevices();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    let filteredList = deviceAllList;

    if (filterData.searchKey !== "") {
      let inputVal = filterData.searchKey;
      inputVal = inputVal.toLowerCase();
      filteredList = filteredList.filter((item) => {
        return (item?.apartment?.apt_no?.toLowerCase().includes(inputVal) || item?.location?.toLowerCase().includes(inputVal) || item?.name?.toLowerCase().includes(inputVal) || item?.product_type?.toLowerCase().includes(inputVal) || item?.property?.name?.toLowerCase().includes(inputVal) || item?.real_name?.toLowerCase().includes(inputVal));
      });
    }

    if (filterData.property !== "" && filterData.property !== "all") {
      filteredList = filteredList.filter((i) => i.property_id === filterData.property);
    }

    if (filterData.apartment !== "" && filterData.apartment !== "all") {
      filteredList = filteredList.filter((i) => i.apt_id === filterData.apartment);
    }

    if (filterData.status !== "" && filterData.status !== "all") {
      filteredList = filteredList.filter((i) => i.online === parseInt(filterData.status));
    }

    if (filterData.location !== "" && filterData.location !== "all") {
      filteredList = filteredList.filter((i) => i.location === filterData.location);
    }

    if (filterData.product !== "" && filterData.product !== "all") {
      filteredList = filteredList.filter((i) => i.product_type === filterData.product);
    }

    setDeviceList(filteredList);
  }, [filterData, deviceAllList]);

  const updateDeviceStatus = (deviceId, deviceStatus, bizCode) => {
    try {
      if (bizCode) {
        const deviceData = deviceAllList.map((device) => {
          if (device.deviceID === deviceId) {
            const isOnline = deviceStatus === "online" ? 1 : 0;
            device.online = isOnline;
            if (isOnline === 0) {
              const currentTimeISO = new Date().toISOString();
              const timeDistance = moment(currentTimeISO).fromNow();
              device.last_seen_str = `${timeDistance}`;
            }
          }
          return device;
        });
        setDeviceAllList([...deviceData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectProperty = (e) => {
    let apartmentListTemp = [];
    propertyList?.map((i) => {
      if (i.value === e.target.value) {
        apartmentListTemp = i.apartments;
      }
      return null;
    });

    apartmentListTemp = [{ value: "all", label: "All", apartments: [] }].concat(apartmentListTemp);
    setApartmentList(apartmentListTemp);
  };

  const onAdd = (data) => {
    setDeviceAllList((prev) => {
      return data.concat(prev);
    });
  };

  return (<React.Fragment>
    {deviceAllList?.length > 0 && (<PubNubChannel ownerId={deviceAllList[0]?.ownerId} updateDeviceStatus={updateDeviceStatus.bind(this)}></PubNubChannel>)}

    <Helmet title="Smart Devices" />

    <div className={`${styles.pageTitleWrap}`}>
      <Typography display="inline" className={`${styles.pageTitle}`}>Smart Devices</Typography>
      <nav aria-label="breadcrumb">
        <ol className={`${styles.breadcrumb}`}>
          <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Smart Devices</li>
        </ol>
      </nav>
    </div>

    <div className={`${styles.BodyCon}`}>
      <div className={`${styles.mainCardDiv}`}>
        <div className={`${styles.mainCard}`}>
          {contentLoading && (<div className="Loader TableLoader">
            <TableLoader />
          </div>)}

          {!contentLoading && (<div className="control-pane">
            <div className={`${styles.Row}`}>

              <div className={`${styles.SearchFilterSec}`}>
                <div className={`${styles.SearchCon}`}>
                  <div className={`${styles.SearchConSec}`}>
                    <Search />
                    <input type="text" placeholder="Search" onChange={(e) => setFilterData((prev) => { return { ...prev, searchKey: e.target.value }; })} />
                  </div>
                </div>

                <div className={`${styles.FilterSec}`}>
                  <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                    <p className={`${styles.FilterTitle}`}>
                      Filters
                      <span onClick={(e) => setFilterOpen(false)}>
                        <ArrowRight />
                      </span>
                    </p>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Property</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Property"
                          value={filterData?.property}
                          onChange={(e) => {
                            setFilterData((prev) => {
                              return { ...prev, property: e.target.value };
                            });
                            selectProperty(e);
                          }}
                        >
                          {propertyList?.map((i) => {
                            return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Apartment</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Apartment"
                          value={filterData?.apartment}
                          onChange={(e) => {
                            setFilterData((prev) => {
                              return { ...prev, apartment: e.target.value };
                            });
                          }}
                        >
                          {apartmentList?.map((i) => {
                            return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Status"
                          value={filterData?.status}
                          onChange={(e) => {
                            setFilterData((prev) => {
                              return { ...prev, status: e.target.value };
                            });
                          }}
                        >
                          {statusList?.map((i) => {
                            return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width150} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Location"
                          onChange={(e) => {
                            setFilterData((prev) => {
                              return { ...prev, location: e.target.value };
                            });
                          }}
                        >
                          {locationtList?.map((i) => {
                            return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Product</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Product"
                          value={filterData?.product}
                          onChange={(e) => {
                            setFilterData((prev) => {
                              return { ...prev, product: e.target.value };
                            });
                          }}
                        >
                          {productList?.map((i) => {
                            return (<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Link className={`${styles.FilterBu}`} onClick={(e) => setFilterOpen(true)}>
                    <Filter />
                  </Link>
                  {/*isAuthenticated && user["https://propertydek.com/permissions"].indexOf("device:create") > -1 && (<button className={`${styles.AddNoteBu}`} onClick={(e) => setShowAddFormNew(true)}><Plus /> <span>Device</span></button>)*/}
                  {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("device:create") > -1 && (<Dropdown>
                    <Dropdown.Toggle className={`${styles.ActionsBU} ${styles.Red} ActionsBU Red`} id="dropdown-basic">
                      <Plus /> <span className={`${styles.AddBuText}`}>Device</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                      <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setShowAddFormNew(true)}><Smartphone /> Tuya Device</Dropdown.Item>
                      <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setShowAddFormNew2(true)}><Key /> TTLock Device</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>)}
                </div>
              </div>

              <div className={`${styles.NotesRow}`}>
                {deviceList.length > 0 && deviceList.map((item, index) => {
                  return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link className={`${styles.ProspectCard}`} to={`/smart-devices/details/${item.id}`}>
                    <div className={`${styles.ProspectCardUnder}`}>
                      <div className={`${styles.ProspectCardHeadSection}`}>
                        <div className={`${styles.ProsIMGSec}`}>
                          {item.product_type === "Propertydek-Door Lock" && (<img src="/static/img/Door.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                          {item.product_type === "Propertydek-Light" && (<img src="/static/img/bulb.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                          {item.product_type === "Propertydek-LED Light" && (<img src="/static/img/LED-light.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                          {item.product_type === "Propertydek-Thermostat" && (<img src="/static/img/temperature.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                          {item.product_type === "Propertydek-Device Gateway" && (<img src="/static/img/router.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                          {item.product_type === "Propertydek-Lock Gateway" && (<img src="/static/img/router.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                          {item.product_type === "Propertydek-Water Meter" && (<img src="/static/img/watermeter.svg" className={`${styles.ProspectCardImg}`} alt="" />)}
                        </div>

                        <div className={`${styles.HeadConSec}`}>
                          <div className={`${styles.prospectNameActionSec}`}>
                            <p className={`${styles.ProsUserName}`}>{item.product_type}</p>
                          </div>
                          <p className={`${styles.ProsUserName}`}>{item.location}</p>
                        </div>
                      </div>
                      <div className={`${styles.ProspectCardBodySec}`}>
                        <ul>
                          <li>
                            <p className={`${styles.Title}`}>Device Name:</p>
                            <Tooltip title={item.name} placement="bottom-start"><p className={`${styles.Content}`}>{item.name}</p></Tooltip>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Real Name:</p>
                            <p className={`${styles.Content}`}>{item.real_name}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Property:</p>
                            <p className={`${styles.Content}`}>{item.property.name}</p>
                          </li>
                          <li>
                            <p className={`${styles.Title}`}>Unit:</p>
                            <p className={`${styles.Content}`}>{item.apartment ? item.apartment.apt_no : item.apt_text}</p>
                          </li>
                        </ul>
                      </div>
                      <div className={`${styles.ProspectCardFootSec}`}>
                        <p className={`${styles.ProsCardDate}`}>{item?.online === 0 && item.last_seen_str}</p>
                        {item?.online === 0 && <span className={`${styles.OfflineBadge}`}>Offline</span>}
                        {item?.online === 1 && <span className={`${styles.OpenBadge}`}>Online</span>}
                      </div>
                    </div>
                  </Link>) : null;
                })}

                {deviceList.length > 0 && (<div className="Pagination TablePaginationNew List">
                  <TablePagination
                    count={deviceList.length}
                    color="primary"
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={(e, v) => setPage(v)}
                    rowsPerPageOptions={[
                      { label: '4', value: 4 },
                      { label: '8', value: 8 },
                      { label: '16', value: 16 },
                      { label: 'All', value: -1 },
                    ]}
                    onRowsPerPageChange={(e) => {
                      setPage(0);
                      setPerPage(e.target.value);
                    }}
                    labelRowsPerPage={'Devices per Page:'}
                  />
                </div>)}

                {deviceList.length === 0 && (<div className={`${styles.NoDataMain}`}>
                  <div className={`${styles.NoDataIMG}`}>
                    <img src="/static/img/noDevice.svg" alt="No Data" />
                  </div>
                  <p className={`${styles.NoDataText}`}>Let's fill it up!</p>
                  <p className={`${styles.NoDataTextSub}`}>Device List is Currently Empty.</p>
                </div>)}
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <Dialog
      open={showAddFormNew}
      onClose={(e) => { setShowAddFormNew(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm CustomWidthPops"
    >
      <AddDeviceFormNew setShowAddFormNew={setShowAddFormNew.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onAdd.bind(this)} />
    </Dialog>

    <Dialog
      open={showAddFormNew2}
      onClose={(e) => { setShowAddFormNew2(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm CustomWidthPops"
    >
      <AddLockFormNew setShowAddFormNew={setShowAddFormNew2.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onAdd.bind(this)} />
    </Dialog>

    <Footer />
  </React.Fragment>);
};

export default withAuthenticationRequired(SmartDevices, {
  onRedirecting: () => <Loader />,
});