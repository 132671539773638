import React, { useEffect, useState } from "react";
import styles from "./alerts.module.css";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../../components/Loader";
import { Helmet } from "react-helmet";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Snackbar, TablePagination, Typography } from "@mui/material";
import { ArrowLeft, Home, X } from "react-feather";
import Footer from "../../../components/Footer";
import { TableLoader } from "../../../components/LoaderC";
import axios from "axios";
import AlertCard from "./components/alertCard";
import AlertFilter from "./components/alertFilter";
import AlertAdd from "./components/alertAdd";
import dayjs from "dayjs";
import { AddLogCallAPI } from "../../../components/AddLogs";
import { toast } from 'react-smart-toaster';

const AlertList = ({ history, match }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [contentLoading, setContentLoading] = useState(true);
    const [allAlertList, setAllAlertList] = useState([]);
    const [alertList, setAlertList] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(8);
    const [filterData, setFilterData] = useState({ searchKey: "", property: "", status: "" });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showdeletePopup, setShowdeletePopup] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [selectecId, setSelectecId] = useState(0);

    useEffect(() => {
        async function getAllAlerts() {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/alerts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setContentLoading(false);
                setAllAlertList(response.data.data);
            }).catch((error) => {
                setContentLoading(false);
            });
        }
        getAllAlerts();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (allAlertList.length > 0) {
            let filteredData = allAlertList;

            if (filterData.searchKey !== "") {
                let inputVal = filterData.searchKey;
                inputVal = inputVal.toLowerCase();
                filteredData = filteredData.filter((item) => {
                    return (item.title.toLowerCase().includes(inputVal) || item.author_name.toLowerCase().includes(inputVal) || item.property_names?.join(', ').toLowerCase().includes(inputVal));
                });
            }

            if (filterData.property !== "" && filterData.property !== "all") {
                filteredData = filteredData.filter((i) => i.property_id_arr.indexOf(filterData.property) > -1);
            }

            if (filterData.status !== "" && filterData.status !== "all") {
                filteredData = filteredData.filter((i) => i.status === filterData.status);
            }

            setAlertList(filteredData);
        } else {
            setAlertList([]);
        }
    }, [filterData, allAlertList]);

    const onAdd = (data) => {
        setAllAlertList((prev) => {
            return [data].concat(prev);
        });
    }

    const onUpdate = (data) => {
        setAllAlertList(prev => {
            let updateList = prev?.map(item => {
                if (item?.id === data?.id)
                    return data;
                else
                    return item;
            })
            return updateList;
        });
    }

    const confirmDelete = async () => {
        setShowdeletePopup(false);
        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.delete(process.env.REACT_APP_RESOURCE_API_URL + 'v1/alerts/' + selectecId, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            setSnackbarOpen(true);
            setSnackbarMsg(response.data.message);
            setLoading(false);

            setAllAlertList(prev => {
                let updateList = prev?.filter(i => i?.id !== selectecId);

                console.log(updateList);
                return updateList;
            })
            setSelectecId(0);

            let logData = {
                'title': 'Comminty board alert is deleted',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Valid On: ' + dayjs(response.data.data?.start_date).format('MMM D, YYYY') + ' to ' + dayjs(response.data.data?.end_date).format('MMM D, YYYY'),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }

            AddLogCallAPI(logData, token);
        }).catch(error => {
            if (typeof error.response !== 'undefined')
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<React.Fragment>
        {loading && <Loader />}

        <Helmet title="Community Board" />
        <div className={`${styles.pageTitleWrap}`}>
            <Typography display="inline" className={`${styles.pageTitle}`}>Community Board</Typography>
            <nav aria-label="breadcrumb">
                <ol className={`${styles.breadcrumb}`}>
                    <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Resources</li>
                    <li className={`${styles.breadcrumbItem} ${styles.active}`}>Community Board</li>
                </ol>
            </nav>
        </div>
        <div className={`${styles.BodyCon}`}>
            <div className={`${styles.mainCardDiv}`}>
                <div className={`${styles.mainCard}`}>
                    <div className="control-pane">
                        {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

                        {!contentLoading && (<div className={`${styles.Row}`}>
                            <AlertFilter setFilterData={setFilterData.bind(this)} setShowAddForm={setShowAddForm.bind(this)} />

                            <div className={`${styles.NotesRow}`}>
                                {alertList.map((item, index) => {
                                    return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<AlertCard item={item} setShowdeletePopup={setShowdeletePopup.bind(this)} setSelectecId={setSelectecId.bind(this)} setShowEditForm={setShowEditForm.bind(this)} />) : null;
                                })}

                                {alertList.length > 0 && (<div className="Pagination TablePaginationNew List">
                                    <TablePagination
                                        count={alertList.length}
                                        color="primary"
                                        page={page}
                                        rowsPerPage={perPage}
                                        onPageChange={(e, v) => setPage(v)}
                                        rowsPerPageOptions={[
                                            { label: '4', value: 4 },
                                            { label: '8', value: 8 },
                                            { label: '16', value: 16 },
                                            { label: 'All', value: -1 },
                                        ]}
                                        onRowsPerPageChange={(e) => {
                                            setPage(0);
                                            setPerPage(e.target.value);
                                        }}
                                        labelRowsPerPage={'Alert per Page:'}
                                    />
                                </div>)}

                                {!contentLoading && alertList.length === 0 && (<div className={`${styles.NoDataMain}`}>
                                    <div className={`${styles.NoDataIMG}`}>
                                        <img src="/static/img/community-alert.png" alt="No Data" />
                                    </div>
                                    <p className={`${styles.NoDataText}`}>Sorry!</p>
                                    <p className={`${styles.NoDataTextSub}`}>You have no new alert at this time.</p>
                                </div>)}

                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>

        <Dialog
            open={showAddForm}
            onClose={(e) => setShowAddForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm CustomWidthLeasePops"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowAddForm(false)}><ArrowLeft /></button>
                New Alert
                <button onClick={(e) => setShowAddForm(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <AlertAdd setShowAddForm={setShowAddForm.bind(this)} setLoading={setLoading.bind(this)} onAdd={onAdd.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} isEdit={false} selectecId={0} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showEditForm}
            onClose={(e) => setShowEditForm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp MobileForm CustomWidthLeasePops"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                <button className={`${styles.BackAddBU}`} onClick={(e) => setShowEditForm(false)}><ArrowLeft /></button>
                Update Alert
                <button onClick={(e) => setShowEditForm(false)}><X /></button>
            </DialogTitle>
            <DialogContent>
                <AlertAdd setShowAddForm={setShowEditForm.bind(this)} setLoading={setLoading.bind(this)} onAdd={onUpdate.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} isEdit={true} selectecId={selectecId} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={showdeletePopup}
            onClose={(e) => setShowdeletePopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="NewPopUp"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete the alert?<button onClick={(e) => setShowdeletePopup(false)}><X /></button></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="ModalFormGroup">
                        <label className="PopupBodyText">This alert will be removed immediately. You can't undo this action.</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="CancelPopupBU" onClick={(e) => confirmDelete()}>Delete</button>
                <button onClick={(e) => setShowdeletePopup(false)} className="SubmitPopupBU">Cancel</button>
            </DialogActions>
        </Dialog>

        <Snackbar
            open={snackbarOpen}
            onClose={(e) => setSnackbarOpen(false)}
            TransitionComponent={Slide}
            message={snackbarMsg}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={(e) => setSnackbarOpen(false)}
                >
                    <X />
                </IconButton>
            }
        />

        <Footer />
    </React.Fragment>);
};

export default withAuthenticationRequired(AlertList, {
    onRedirecting: () => <Loader />,
});