import { useEffect, useState } from "react";
import styles from "../articles.module.css";
import { ArrowRight, Filter, Plus, Search } from "react-feather";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const ArticleFilter = ({ setFilterData, setShowAddForm }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [filterOpen, setFilterOpen] = useState(false);
    const [propertyList, setPropertyList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        const getPropertyList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/property-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setPropertyList([{ id: 'all', name: 'Any' }].concat(response.data.data));
            }).catch(() => {
                setPropertyList([]);
            });
        }

        getPropertyList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const getCategoryList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/article-category-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setCategoryList([{ id: 'all', title: 'Any' }].concat(response.data.data));
            }).catch(() => {
                setCategoryList([]);
            });
        }

        getCategoryList();
    }, [getAccessTokenSilently]);

    return (<div className={`${styles.SearchFilterSec}`}>
        <div className={`${styles.SearchCon}`}>
            <div className={`${styles.SearchConSec}`}>
                <Search />
                <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
            </div>
        </div>

        <div className={`${styles.FilterSec}`}>
            <div className={filterOpen === true ? `${styles.FilterFieldSec} ${styles.Open}` : `${styles.FilterFieldSec}`}>
                <p className={`${styles.FilterTitle}`}>
                    Filters <span onClick={(e) => setFilterOpen(false)}><ArrowRight /></span>
                </p>
                <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Property</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Property"
                            onChange={(e) => setFilterData(prev => {
                                return { ...prev, property: e.target.value }
                            })}
                        >
                            {propertyList?.map(item => {
                                return (<MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={`${styles.FilterCard} ${styles.Select} ${styles.Width200} CusSelectFilter Filter`}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Category"
                            onChange={(e) => setFilterData(prev => {
                                return { ...prev, category: e.target.value }
                            })}
                        >
                            {categoryList?.map(item => {
                                return (<MenuItem key={item?.id} value={item?.id}>{item?.title}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <button className={`${styles.FilterBu}`} onClick={(e) => setFilterOpen(true)}><Filter /></button>
            <button className={`${styles.AddNoteBu}`} onClick={(e) => setShowAddForm(true)}><Plus /> <span>Article</span></button>
        </div>
    </div>)
}

export default ArticleFilter;