import styles from "../../../../maintenancerequestdetails.module.css";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { Edit, Plus, X, Trash2, ArrowLeft } from "react-feather";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../../../components/AddLogs";
import AppointmentAdd from "./AppointmentAdd";
import dayjs from "dayjs";

const AppointmentList = ({ requestData, appointments, setRequestData, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [appointmentList, setAppointmentList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [appDeleteId, setAppDeleteId] = useState(0);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [appEditId, setAppEditId] = useState(0);
  const [appEditData, setAppEditData] = useState(null);

  useEffect(() => {
    if (appointments) {
      setAppointmentList(appointments);
    }
  }, [appointments]);

  const delConfirm = async () => {
    setDeleteModalOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    return axios.delete(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointments/" + appDeleteId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarOpen(true);
      setSnackbarMsg(response.data.message);

      let appointment = appointments.filter((item) => item.id === appDeleteId);

      setRequestData((prev) => {
        let appointments = prev?.appointments?.filter((i) => i?.id !== appDeleteId);
        return { ...prev, appointments: appointments };
      });

      let logData = {
        title: "Maintenance request appointment is deleted",
        description: [
          "Name: " + requestData?.title,
          "Request number: " + requestData?.request_no,
          "Property: " + requestData?.property_name,
          "Apartment: " + requestData?.apt_name,
          "Tenant: " + requestData?.tenant_name,
          "Technician: " + appointment[0].assignee_name,
          "Appointment date: " + dayjs(appointment[0].date).format("L"),
          "Appointment time: " + appointment[0].time,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  return (<>
    <div className={`${styles.WorkOrderCard}`}>

      {appointmentList?.length > 0 && (<div className={`${styles.WorkOrderCardUnder}`}>
        <div className={`${styles.WorkOrderCardHead}`}>
          <div className={`${styles.TitleSec}`}>
            <img src="/static/img/appointments-icon.svg" alt="" />
            <p>Appointments</p>
          </div>
          <button className={`${styles.WorkOrderAddBU}`} onClick={(e) => { setAddModalOpen(true); setAppEditId(0); setAppEditData(null); }}>
            <Plus /> <span>Appointment</span>
          </button>
        </div>
        <div className={`${styles.WorkOrderCardBody}`}>
          <table className={`${styles.WorkOrderTable} ${styles.Table1}`}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Technician Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appointmentList?.map((item) => {
                return (<tr>
                  <td className={`${styles.WorkOrderTableBody}`}>
                    <div><p>{dayjs(item.date).format("L")}</p></div>
                  </td>
                  <td className={`${styles.WorkOrderTableBody}`}>
                    <div><p>{item.time}</p></div>
                  </td>
                  <td className={`${styles.WorkOrderTableBody}`}>
                    <div> <p>{item.assignee_name}</p></div>
                  </td>
                  <td className={`${styles.WorkOrderTableBody}`}>
                    <div className={`${styles.Action}`}>
                      <button onClick={(e) => { setAddModalOpen(true); setAppEditId(item?.id); setAppEditData(item); }}>
                        <Edit />
                      </button>
                      <button onClick={(e) => { setDeleteModalOpen(true); setAppDeleteId(item?.id); }}>
                        <Trash2 />
                      </button>
                    </div>
                  </td>
                </tr>);
              })}
            </tbody>
          </table>
        </div>
      </div>)}

      {appointmentList?.length === 0 && (<div className={`${styles.NoDataMainWithBU}`}>
        <div>
          <div className={`${styles.NoDataIMGWithBU}`}><img src="/static/img/appointments-icon.svg" alt="" /></div>
          <p className={`${styles.NoDataTextWithBU}`}>Appointments List is Empty</p>
          <p className={`${styles.NoDataTextSubWithBU}`}>What do you want to do today?</p>
          <button className={`${styles.AddLeaseBUWithBU}`} onClick={(e) => { setAddModalOpen(true); setAppEditId(0); setAppEditData(null); }}>
            <Plus /> Add New
          </button>
        </div>
      </div>)}
    </div>

    <Dialog
      open={deleteModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setDeleteModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete the appointment?<button onClick={(e) => setDeleteModalOpen(false)}><X /></button></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The appointment will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => delConfirm()}>Delete</Button>
        <Button className="SubmitPopupBU" onClick={(e) => setDeleteModalOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={addModalOpen}
      fullWidth
      maxWidth="xs"
      onClose={(e) => setAddModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddModalOpen(false)}><ArrowLeft /></button>
        {appEditId ? "Edit Appointment" : "Add Appointment"}
        <button onClick={(e) => setAddModalOpen(false)}><X /></button>
      </DialogTitle>
      {/* <DialogTitle id="alert-dialog-title">{appEditId ? "Edit Appointment" : "Add Appointment"}<button onClick={(e) => setAddModalOpen(false)}><X /></button></DialogTitle> */}
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <DialogContentText id="alert-dialog-description">
          <AppointmentAdd
            appEditId={appEditId}
            appEditData={appEditData}
            requestData={requestData}
            setRequestData={setRequestData.bind(this)}
            setAddModalOpen={setAddModalOpen.bind(this)}
            setSnackbarOpen={setSnackbarOpen.bind(this)}
            setSnackbarMsg={setSnackbarMsg.bind(this)}
            setLoading={setLoading.bind(this)}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  </>);
};

export default AppointmentList;