import React, { useEffect, useRef, useState } from "react";
import socket from "../../services/socketService";
import { useHistory, Link as Linkto } from "react-router-dom";
import styles from "./prospectdetails.module.css";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Dropdown } from "react-bootstrap";
import Helmet from "react-helmet";
import Footer from "../../components/Footer";
import { ChevronDown, MoreVertical, ArrowLeft, X, Home, Flag, Trash2, ChevronsLeft, Edit } from "react-feather";
import axios from "axios";
import { toast } from "react-smart-toaster";
import Loader from "../../components/Loader";
import { TableLoader } from "../../components/LoaderC";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import SimpleTabs from "./Components/SimpleTabs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Snackbar, Slide, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, FormControl, InputLabel, Select, MenuItem, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AddLogCallAPI } from "../../components/AddLogs";
import AddTourTileSlot from "./Components/AddTourTileSlot";
import LeaseForm from "./Components/LeaseForm";
import EditPropsectNew from "./Components/EditPropspectNew";
import { useDispatch } from "react-redux";
import { communicationChatReload } from "../../redux/actions/communicationChatRloadAction";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ProspectDetails = ({ history, match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const redirect = useHistory();
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const initializedSocketRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [prospectData, setProspectData] = useState(null);
  const [currentApplicant, setCurrentApplicant] = useState(0);
  const [currentApplicantData, setCurrentApplicantData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [addTourModalOpen, setAddTourModalOpen] = useState(false);
  const [selectedTourDate, setSelectedTourDate] = useState(dayjs());
  const [selectedTourTime, setSelectedTourTime] = useState("");
  const [tourTimeSlotError, setTourTimeSlotError] = useState("");
  const [editTourData, setEditTourData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isApprovedModalOpen, setIsApprovedModalOpen] = useState(false);
  const [isDeclinedModalOpen, setIsDeclinedModalOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const prospectDataRef = useRef(prospectData);
  const chatMessagesRef = useRef(chatMessages);
  const isChatWindowOpenRef = useRef(false);
  const [addLeaseModalOpen, setAddLeaseModalOpen] = useState(false);
  const [leaseFormData, setLeaseFormData] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState("");
  const [tourAptSlotError, setTourAptSlotError] = useState("");
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchProspect = async () => {
      setContentLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setContentLoading(false);
        let dataTemp = response.data.data;
        setProspectData(response.data.data);
        prospectDataRef.current = response.data.data;
        const payload = { conversationId: response?.data?.data?.conversation_id };
        socket.emit("/request-conversation-for-prospect-remove", payload);

        dataTemp?.all_applicants?.map((item, index) => {
          if (parseInt(item?.id) === parseInt(id)) {
            setCurrentApplicant(index);
          }
          return true;
        });
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setContentLoading(false);
      });
    };
    fetchProspect();
  }, [id, getAccessTokenSilently]);

  useEffect(() => {
    async function fetchApartment() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      await axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/apartments/" + prospectData?.property_id, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setApartments(response?.data?.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
    if (prospectData?.property_id) {
      fetchApartment();
    }
  }, [prospectData?.property_id, getAccessTokenSilently]);

  useEffect(() => {
    setCurrentApplicantData(prospectData?.all_applicants[currentApplicant]);
  }, [prospectData, currentApplicant]);

  useEffect(() => {
    try {
      if (true) {
        initializedSocketRef.current = true;
        const getConversationHandler = (messages) => {
          setChatMessages(messages.data);
          chatMessagesRef.current = messages.data;
        };
        const broadcastMessageHandler = (msg) => {
          try {
            let message = msg.data;

            let latestProspectData = prospectDataRef.current;
            let latestChatData = chatMessagesRef.current;

            if (latestProspectData.conversation_id === message.cid) {
              setChatMessages([...latestChatData, message]);
              chatMessagesRef.current = [...latestChatData, message];

              if (!isChatWindowOpenRef.current) {
                latestProspectData.unread_no = latestProspectData.unread_no + 1;

                const updateProspectData = { ...latestProspectData };
                setProspectData({ ...updateProspectData });
                prospectDataRef.current = { ...updateProspectData };
              }
            }
          } catch (error) {
            console.log(error);
          }
        };
        socket.on("broadcast-message-for-prospect-remove", broadcastMessageHandler);

        socket.on("get-conversation-for-prospect-remove", getConversationHandler);

        socket.on("error-handler-for-prospect-remove", (error) => {
          toast.error("Internal server error");
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const addNoteData = (data) => {
    let noteList = prospectData?.notes;
    noteList = [data].concat(noteList);
    setProspectData((prevVal) => {
      return { ...prevVal, notes: noteList };
    });
  };

  const editNoteData = (data, id) => {
    let noteList = prospectData?.notes;
    noteList = noteList.map((item) => {
      if (item.id === id) return data;
      return item;
    });
    setProspectData((prevVal) => {
      return { ...prevVal, notes: noteList };
    });
  };

  const deleteNoteData = (nId) => {
    let noteList = prospectData?.notes;
    noteList = noteList.filter((i) => i?.id !== nId);
    setProspectData((prevVal) => {
      return { ...prevVal, notes: noteList };
    });
  };

  const updateFileList = (files, applicantIndex) => {
    let allApplicants = prospectData?.all_applicants?.map((item, index) => {
      if (index === applicantIndex) {
        return { ...item, files: files };
      }
      return item;
    });
    setProspectData((prevVal) => {
      return { ...prevVal, all_applicants: allApplicants };
    });
  };

  const updateMandatoryChecks = (mData) => {
    let allApplicants = prospectData?.all_applicants?.map((item, index) => {
      if (index === 0) {
        if (typeof mData?.credit_background_check_1 !== "undefined") {
          item = { ...item, credit_background_check: mData?.credit_background_check_1 };
        }
        if (typeof mData?.employement_verification_1 !== "undefined") {
          item = { ...item, employement_verification: mData?.employement_verification_1 };
        }
        if (typeof mData?.income_verification_1 !== "undefined") {
          item = { ...item, income_verification: mData?.income_verification_1 };
        }
        if (typeof mData?.landlord_verification_1 !== "undefined") {
          item = { ...item, landlord_verification: mData?.landlord_verification_1 };
        }
        if (typeof mData?.application_fee_collected_1 !== "undefined") {
          item = { ...item, application_fee_collected: mData?.application_fee_collected_1 };
        }
      }
      if (index === 1) {
        if (typeof mData?.credit_background_check_2 !== "undefined") {
          item = { ...item, credit_background_check: mData?.credit_background_check_2 };
        }
        if (typeof mData?.employement_verification_2 !== "undefined") {
          item = { ...item, employement_verification: mData?.employement_verification_2 };
        }
        if (typeof mData?.income_verification_2 !== "undefined") {
          item = { ...item, income_verification: mData?.income_verification_2 };
        }
        if (typeof mData?.landlord_verification_2 !== "undefined") {
          item = { ...item, landlord_verification: mData?.landlord_verification_2 };
        }
        if (typeof mData?.application_fee_collected_2 !== "undefined") {
          item = { ...item, application_fee_collected: mData?.application_fee_collected_2 };
        }
      }
      return item;
    });

    setProspectData((prevVal) => {
      return { ...prevVal, all_applicants: allApplicants };
    });
  };

  useEffect(() => {
    if (selectedApartment !== "") {
      setTourAptSlotError("");
    }
  }, [selectedApartment]);

  useEffect(() => {
    if (selectedTourTime !== "") {
      setTourTimeSlotError("");
    }
  }, [selectedTourTime]);

  useEffect(() => {
    if (editTourData) {
      setSelectedTourDate(dayjs(editTourData?.datef));
      setSelectedTourTime(editTourData?.timef);
      setSelectedApartment(editTourData?.apt_id);
    } else {
      setSelectedTourDate(dayjs());
      setSelectedTourTime("");
      setSelectedApartment(prospectData?.apt_id);
    }
  }, [editTourData, prospectData?.apt_id]);

  const addTour = async () => {
    if (selectedApartment === "") {
      setTourAptSlotError("Please select apartment.");
      return false;
    }

    if (selectedTourTime === "") {
      setTourTimeSlotError("Please select time slot.");
      return false;
    }
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let postData = {};
    postData = { ...postData, date_time: dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime, apt_id: selectedApartment };
    postData = { ...postData, property_id: prospectData?.property_id };

    setLoading(true);
    axios.post(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + prospectData?.applicant_id + "/tours", postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);
      setAddTourModalOpen(false);
      setSelectedTourDate(dayjs());
      setSelectedTourTime("");
      setSelectedApartment(prospectData?.apt_id ? prospectData?.apt_id : "");

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let tourlist = prospectData?.tours;
      tourlist.push(response.data.data);
      setProspectData((prevVal) => {
        return { ...prevVal, tours: tourlist };
      });

      let logData = {
        title: "A new prospect tour is added",
        description: [
          "Name: " + prospectData?.all_applicants[0]?.full_name,
          "Email: " + prospectData?.all_applicants[0]?.email,
          "Phone: " + prospectData?.all_applicants[0]?.phone,
          "Date/Time: " + response.data.data?.formatted_date + " " + response.data.data?.formatted_time,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const updateTour = async () => {
    if (selectedApartment === "") {
      setTourAptSlotError("Please select apartment.");
      return false;
    }

    if (selectedTourTime === "") {
      setTourTimeSlotError("Please select time slot.");
      return false;
    }
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    let postData = {};
    postData = { ...postData, date_time: dayjs(selectedTourDate).format("YYYY-MM-DD") + " " + selectedTourTime, apt_id: selectedApartment };
    postData = { ...postData, property_id: prospectData?.property_id };

    setLoading(true);
    axios.put(process.env.REACT_APP_APPLICANT_API_URL + "v1/applicants/" + prospectData?.applicant_id + "/tours/" + editTourData?.id, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);
      setAddTourModalOpen(false);
      setSelectedTourDate(dayjs());
      setSelectedTourTime("");
      setSelectedApartment(prospectData?.apt_id ? prospectData?.apt_id : "");

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      let tourlist = prospectData?.tours;
      tourlist = tourlist?.map((item) => {
        if (editTourData?.id === item?.id) {
          return response.data.data;
        }
        return item;
      });
      setProspectData((prevVal) => {
        return { ...prevVal, tours: tourlist };
      });

      let logData = {
        title: "A prospect tour is updated",
        description: [
          "Name: " + prospectData?.all_applicants[0]?.full_name,
          "Email: " + prospectData?.all_applicants[0]?.email,
          "Phone: " + prospectData?.all_applicants[0]?.phone,
          "Date/Time: " + response.data.data?.formatted_date + " " + response.data.data?.formatted_time,
        ],
      };
      AddLogCallAPI(logData, token);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const updateTourList = (tours) => {
    setProspectData((prevVal) => {
      return { ...prevVal, tours: tours };
    });
  };

  const updateApplicantData = (data) => {
    let allApplicants = prospectData?.all_applicants?.map((item, index) => {
      if (item?.id === data?.id) {
        return data;
      }
      return { ...item, apt_id: data?.apt_id, apt_no: data?.apt_no };
    });
    setProspectData((prevVal) => {
      return { ...prevVal, all_applicants: allApplicants };
    });
  };

  const deleteConfirm = async () => {
    setIsDeleteModalOpen(false);
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.delete(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/" + prospectData?.applicant_id, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);
      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);
      dispatch(communicationChatReload(true));

      let respData = response.data.data;

      let logData = {
        title: "Prospect is deleted",
        description: [
          "Name: " + respData?.full_name,
          "Email: " + respData?.email,
          "Phone: " + respData?.phone,
          "Property: " + respData?.property_name,
          "Apartment: " + respData?.apt_no,
        ],
      };
      AddLogCallAPI(logData, token);

      setTimeout(() => {
        redirect.push("/people/prospects");
      }, 3000);
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const handleChangeStatus = async (status) => {
    if (status === 3 && !applicantVerification()) {
      toast.error("All (5) mandatory checks must be completed for each prospect before you do this action");
      return false;
    }
    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/change-status/" + prospectData?.applicant_id + "/" + status, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);

      setSnackbarMsg(response.data.message);
      setSnackbarOpen(true);

      setProspectData((prevVal) => {
        return { ...prevVal, current_status: status };
      });
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const applicantVerification = () => {
    let is_checked = true;

    prospectData?.all_applicants?.map((item) => {
      if (item?.credit_background_check === 0) is_checked = false;
      if (item?.employement_verification === 0) is_checked = false;
      if (item?.income_verification === 0) is_checked = false;
      if (item?.landlord_verification === 0) is_checked = false;
      if (item?.application_fee_collected === 0) is_checked = false;

      return null;
    });
    return is_checked;
  };

  const moveInApplicantChecking = async (event) => {
    if (prospectData?.current_status !== 3) {
      toast.error("Status must be set to Approved before you do this action");
      return false;
    }

    if (prospectData?.no_of_applicants === 2 && prospectData?.no_of_applicants !== prospectData?.all_applicants?.length) {
      toast.error("Second applicant data is not submitted yet.");
      return false;
    }

    setLoading(true);
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    axios.get(process.env.REACT_APP_APPLICANT_API_URL + "v1/prospects/check-duplicate/" + prospectData?.applicant_id, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false);
      let respData = response.data;
      if (respData.status === false) {
        toast.error(respData.message);
      } else {
        setAddLeaseModalOpen(true);
        setLeaseFormData(response.data.data);
      }
    }).catch((error) => {
      if (typeof error.response !== "undefined")
        toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  const getIsSumitted = () => {
    let is_submitted = true;

    prospectData?.all_applicants?.map((item) => {
      if (item?.is_submitted === 0) is_submitted = false;

      return null;
    });
    return is_submitted;
  };

  return (<React.Fragment>
    {loading && <Loader />}

    {!contentLoading && <Helmet title={currentApplicantData?.full_name} />}

    <div className={`${styles.mainCard}`}>
      {contentLoading && (<div className="Loader TableLoader"><TableLoader /></div>)}

      {!contentLoading && (<div>
        <div className={`${styles.AppliSwitchRow}`}>
          <div className={`${styles.AppliOne}`}>
            <div className={`${styles.SMProCusRow}`}>
              <div className={`${styles.BreadcrumbHeadSec}`}>
                <nav aria-label="breadcrumb">
                  <ol className={`${styles.breadcrumb}`}>
                    <li className={`${styles.breadcrumbItem}`}><span><Home /></span>Prospect</li>
                    <li className={`${styles.breadcrumbItem}`}>
                      <Linkto to={"/people/prospects"}>All Prospect</Linkto>
                    </li>
                    <li className={`${styles.breadcrumbItem} ${styles.active}`}>{currentApplicantData?.full_name}</li>
                  </ol>
                </nav>
                <div className={`${styles.PageTitleSec}`}>
                  <div>
                    <button onClick={() => redirect.goBack()} className={`${styles.BackArrow}`}><ArrowLeft /></button>
                    <Typography display="inline" className={`${styles.pageTitle}`}>{currentApplicantData?.full_name}</Typography>
                  </div>
                </div>
              </div>

              <div className={`${styles.SMProCusSmallDiv}`}>
                <div className={`${styles.CardSmall}`}>
                  <div className={`${styles.SMProCardHead}`}>
                    <div className={prospectData?.all_applicants?.length > 1 ? `${styles.SMProPicMainDubble}` : `${styles.SMProPicMain}`}>
                      {prospectData?.all_applicants?.map((item, index) => {
                        return (<Tooltip title={item?.full_name} placement="bottom" key={index}>
                          <div className={index === currentApplicant ? `${styles.avatar} ${styles.active}` : `${styles.avatar}`} onClick={(e) => setCurrentApplicant(index)}>
                            {item?.initial !== "?" && (<p>{item?.initial}</p>)}
                            {item?.initial === "?" && (<img src="/static/img/DefaultLogo.png" className={`${styles.defaultImg}`} alt="" />)}
                          </div>
                        </Tooltip>);
                      })}
                    </div>
                    <div className={`${styles.ProsUserMandateSec}`}>
                      <Tooltip title={currentApplicantData?.credit_score !== "" ? "Credit Score - " + currentApplicantData?.credit_score : "Credit Score - N/A"}>
                        <span className={currentApplicantData?.check_list?.credit_score === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : currentApplicantData?.check_list?.credit_score === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                          <img src="/static/img/credit-score-icon.svg" alt="" />
                        </span>
                      </Tooltip>
                      <Tooltip title={currentApplicantData?.income_per_year !== "" ? "Salary - $" + currentApplicantData?.formatted_income_per_year : "Salary - N/A"}>
                        <span className={currentApplicantData?.check_list?.income === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : currentApplicantData?.check_list?.income === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                          <img src="/static/img/salary-icon.svg" alt="" />
                        </span>
                      </Tooltip>
                      <Tooltip title={currentApplicantData?.ever_had_bankruptcy !== "" ? "Bankruptcy - " + currentApplicantData?.ever_had_bankruptcy : "Bankruptcy - N/A"}>
                        <span className={currentApplicantData?.check_list?.bankruptcy === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : currentApplicantData?.check_list?.bankruptcy === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                          <img src="/static/img/bankruptcy-icon.svg" alt="" />
                        </span>
                      </Tooltip>
                      <Tooltip title={currentApplicantData?.ever_case_filed !== "" ? "Housing Court Cases - " + currentApplicantData?.ever_case_filed : "Housing Court Cases - N/A"}>
                        <span className={currentApplicantData?.check_list?.housing_court_case === 2 ? `${styles.MandateCheckSec} ${styles.Fail}` : currentApplicantData?.check_list?.housing_court_case === 1 ? `${styles.MandateCheckSec} ${styles.Active}` : `${styles.MandateCheckSec}`}>
                          <img src="/static/img/court-cases-icon.svg" alt="" />
                        </span>
                      </Tooltip>
                    </div>

                    <h5>{currentApplicantData?.full_name !== "" ? currentApplicantData?.full_name : "-"}</h5>
                    <Dropdown className={`${styles.ProEditSec}`}>
                      <Dropdown.Toggle className={`${styles.EditActionsBU} ProsDrBU`} id="dropdown-basic">
                        <MoreVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setEditModalOpen(true)}>
                          <Edit /> Edit
                        </Dropdown.Item>
                        {(prospectData?.is_prospect_applied === 1) & getIsSumitted() ? (<Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={moveInApplicantChecking.bind(this)}>
                          <ChevronsLeft /> Move-in Applicant
                        </Dropdown.Item>) : null}
                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setAddTourModalOpen(true); setTourTimeSlotError(""); setEditTourData(null); setTourAptSlotError(""); }}>
                          <Flag /> Add Tour
                        </Dropdown.Item>
                        <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => setIsDeleteModalOpen(true)}>
                          <Trash2 /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="HistoryAccording Theme">
                    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <div className={`${styles.FormAccoHead}`}>
                          <p className={`${styles.FormAccoHeadTitlePro}`}>Prospect details</p>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={`${styles.SMProCardDe}`}>
                          <ul>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Email:</span>
                              <span className={`${styles.SMProDE}`}>
                                {currentApplicantData?.email !== "" ? currentApplicantData?.email : "-"}
                              </span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Phone:</span>
                              <span className={`${styles.SMProDE}`}> {currentApplicantData?.phone}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Property:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.property_name !== "" ? currentApplicantData?.property_name : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Apartment:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.apt_no !== "" ? currentApplicantData?.apt_no : "-"}</span>
                            </li>
                          </ul>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                        <div className={`${styles.FormAccoHead}`}>
                          <p className={`${styles.FormAccoHeadTitlePro}`}>Score Card</p>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={`${styles.cardUnderSHead}`}>
                          <div className={`${styles.HeadIcon}`}>
                            <img src="/static/img/scoreCardImg.png" alt="" />
                          </div>
                          <div className={`${styles.HeadCon}`}>
                            <p className={`${styles.HeadConTitle}`}>Score Card</p>
                            <p className={`${styles.HeadConText}`}>Last Updated: {moment(currentApplicantData?.updated_at).format("MM/DD/YYYY")}</p>
                          </div>
                        </div>
                        <div className={`${styles.SMScoreCardDe}`}>
                          <p className={`${styles.SMScoreCardDeTitle}`}>Personal Information</p>
                          <ul>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Credit Score:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.credit_score !== "" ? currentApplicantData?.credit_score : "-"}{" "}{parseInt(currentApplicantData?.is_credit_score_verify) === 1 && (<img src="/static/img/verifiedW.svg" className={`${styles.Varified}`} alt="" />)}
                              </span>
                            </li>
                            {currentApplicantData?.income_type === 'Voucher' ? <li>
                              <span className={`${styles.SMProDETag}`}>Voucher:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.voucher_amount !== "" ? currentApplicantData?.voucher_amount : "-"}</span>
                            </li> : <li>
                              <span className={`${styles.SMProDETag}`}>Salary:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.income_per_year !== "" ? "$" + currentApplicantData?.formatted_income_per_year : "-"}</span>
                            </li>}
                            <li>
                              <span className={`${styles.SMProDETag}`}>Bankruptcy:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.ever_had_bankruptcy !== "" ? currentApplicantData?.ever_had_bankruptcy : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Housing Court Cases:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.ever_case_filed !== "" ? currentApplicantData?.ever_case_filed : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Current Monthly Rent:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.current_rent !== "" ? "$" + currentApplicantData?.formatted_current_rent
                                : "-"}</span>
                            </li>
                          </ul>
                        </div>
                        <div className={`${styles.SMScoreCardDe}`}>
                          <p className={`${styles.SMScoreCardDeTitle}`}>Preferences</p>
                          <ul>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Move-In Date:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.move_in_date !== "" ? moment(currentApplicantData?.move_in_date).format("MM/DD/YYYY") : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Bedrooms:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.bedrooms !== "" ? currentApplicantData?.bedrooms : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Bathrooms:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.bathrooms > 0 ? currentApplicantData?.bathrooms : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Pets:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.have_pets !== "" && currentApplicantData?.have_pets !== "0" ? currentApplicantData?.have_pets : "-"}</span>
                            </li>
                            <li>
                              <span className={`${styles.SMProDETag}`}>Smoke:</span>
                              <span className={`${styles.SMProDE}`}>{currentApplicantData?.do_you_smoke !== "" ? currentApplicantData?.do_you_smoke : "-"}</span>
                            </li>
                          </ul>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>

              <div className={`${styles.SMProCusBigDiv}`}>
                <div className={`${styles.card}`}>
                  <div className={`${styles.cardbody}`}>
                    <div className={`${styles.SMBigSideHead}`}>
                      <h5>Activities</h5>
                      {prospectData?.current_status > 0 ? (<div>
                        {isAuthenticated && user["https://propertydek.com/permissions"].indexOf("prospect:update") > -1 && (<Dropdown>
                          {prospectData?.current_status === 1 && (<Dropdown.Toggle id="dropdown-basic" className={`${styles.ReferStatusBU} ${styles.Prequalified}`}>
                            Applied<ChevronDown className={`${styles.DownArrowStatus}`} />
                          </Dropdown.Toggle>)}

                          {prospectData?.current_status === 2 && (<Dropdown.Toggle id="dropdown-basic" className={`${styles.ReferStatusBU} ${styles.StatusBuYellow}`}>
                            Screened<ChevronDown className={`${styles.DownArrowStatus}`} />
                          </Dropdown.Toggle>)}

                          {prospectData?.current_status === 3 && (<Dropdown.Toggle id="dropdown-basic" className={`${styles.ReferStatusBU} ${styles.Approved}`}>
                            Approved<ChevronDown className={`${styles.DownArrowStatus}`} />
                          </Dropdown.Toggle>)}

                          {prospectData?.current_status === 4 && (<Dropdown.Toggle id="dropdown-basic" className={`${styles.ReferStatusBU} ${styles.StatusBuRed}`}>
                            Declined<ChevronDown className={`${styles.DownArrowStatus}`} />
                          </Dropdown.Toggle>)}

                          <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                            <Dropdown.Item className={`${styles.EditActionsBUListItemSmall}`} disabled>Applied</Dropdown.Item>
                            <Dropdown.Item className={`${styles.EditActionsBUListItemSmall}`} disabled>Screened</Dropdown.Item>
                            <Dropdown.Item className={`${styles.EditActionsBUListItemSmall}`} onClick={(e) => setIsApprovedModalOpen(true)}>Approved</Dropdown.Item>
                            <Dropdown.Item className={`${styles.EditActionsBUListItemSmall}`} onClick={(e) => setIsDeclinedModalOpen(true)}>Declined</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>)}
                      </div>) : (<div>
                        <span className={`${styles.ReferStatusBUNotApplied}`}>Not Applied</span>
                      </div>)}
                    </div>

                    <div className={`${styles.SMBigSideBody} TabMainClientProfile ClientDetails`}>
                      <SimpleTabs
                        tabValue={tabValue}
                        handleTabChange={handleTabChange.bind(this)}
                        prospectData={prospectData}
                        addNoteData={addNoteData.bind(this)}
                        editNoteData={editNoteData.bind(this)}
                        deleteNoteData={deleteNoteData.bind(this)}
                        updateFileList={updateFileList.bind(this)}
                        updateTourList={updateTourList.bind(this)}
                        setAddTourModalOpen={setAddTourModalOpen.bind(this)}
                        setEditTourData={setEditTourData.bind(this)}
                        setSnackbarMsg={setSnackbarMsg.bind(this)}
                        setSnackbarOpen={setSnackbarOpen.bind(this)}
                        setTourTimeSlotError={setTourTimeSlotError.bind(this)}
                        updateMandatoryChecks={updateMandatoryChecks.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </div>

    <Dialog
      open={isDeleteModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setIsDeleteModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete the Prospect Record?
        <button onClick={(e) => setIsDeleteModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">The Prospect Record will be removed immediately.You can't undo this action.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteConfirm.bind(this)} className="CancelPopupBU">Delete</Button>
        <Button onClick={(e) => setIsDeleteModalOpen(false)} className="SubmitPopupBU">Cancel</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={isApprovedModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setIsApprovedModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to Approve the Prospect Record?
        <button onClick={(e) => setIsApprovedModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">Once approved, the Prospect will receive an automated SMS notification. This action is permanent.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => setIsApprovedModalOpen(false)}>Cancel</Button>
        <Button onClick={(e) => { setIsApprovedModalOpen(false); handleChangeStatus(3); }} className="SubmitPopupBU">Confirm</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={isDeclinedModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setIsDeclinedModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to decline the Prospect Record?
        <button onClick={(e) => setIsDeclinedModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="ModalFormGroup">
            <label className="PopupBodyText">Declining sends an immediate email notification. This action is permanent.</label>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="CancelPopupBU" onClick={(e) => setIsDeclinedModalOpen(false)}>Cancel</Button>
        <Button onClick={(e) => { setIsDeclinedModalOpen(false); handleChangeStatus(4); }} className="SubmitPopupBU">Confirm</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={addTourModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddTourModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm CustomWidthPopUs"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddTourModalOpen(false)}><ArrowLeft /></button>
        {editTourData?.id > 0 ? "Update Tour" : "Add Tour"}
        <button onClick={(e) => setAddTourModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <div className={`${styles.FilterCard} ${styles.Select} CusSelectFilter AddTour`}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Apartment*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={"Apartment*"}
              value={selectedApartment}
              onChange={(e) => setSelectedApartment(e.target.value)}
            >
              {apartments?.map((item) => {
                return (<MenuItem key={item?.value} value={item?.value}>{item?.label}</MenuItem>);
              })}
            </Select>
          </FormControl>
          <p className={`${styles.AddTourErrorMessage}`}>{tourAptSlotError !== "" && tourAptSlotError}</p>
        </div>
        <p className={`${styles.AddTourPopTT}`}>Select Tour Date and Time</p>
        <div className={`${styles.ModalFormGroup} ModalFormGroup`}>
          <div className={`${styles.AddTourModal} AddTourModal`}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                orientation="landscape"
                defaultValue={selectedTourDate}
                minDate={dayjs()}
                onChange={(e) => setSelectedTourDate(e)}
              />
            </LocalizationProvider>
          </div>
          <div className={`${styles.AddTourTimeArea}`}>
            <p className={`${styles.TimeSlotTitle}`}>{dayjs(selectedTourDate).format("dddd, MMMM D")}</p>
            <p className={`${styles.AddTourErrorMessage}`}>{tourTimeSlotError !== "" && tourTimeSlotError}</p>
            <AddTourTileSlot
              setSelectedTourTime={setSelectedTourTime.bind(this)}
              selectedTourDate={selectedTourDate}
              selectedTourTime={selectedTourTime}
              selectProperty={prospectData?.property_id}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setAddTourModalOpen(false)} className="CancelPopupBU">Cancel</Button>
        {editTourData?.id > 0 ? (<Button className="SubmitPopupBU" onClick={updateTour}>Update</Button>) : (<Button lassName="SubmitPopupBU" onClick={addTour}>Add</Button>)}
      </DialogActions>
    </Dialog>

    <Dialog
      open={addLeaseModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setAddLeaseModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp CustomWidthPopUs"
    >
      <LeaseForm
        setAddLeaseModalOpen={setAddLeaseModalOpen.bind(this)}
        leaseFormData={leaseFormData}
        applicant_id={prospectData?.applicant_id}
        setLoading={setLoading.bind(this)}
        setSnackbarMsg={setSnackbarMsg.bind(this)}
        setSnackbarOpen={setSnackbarOpen.bind(this)}
      />
    </Dialog>

    <Dialog
      open={editModalOpen}
      fullWidth
      maxWidth="sm"
      onClose={(e) => setEditModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setEditModalOpen(false)}>
          <ArrowLeft />
        </button>
        <span>Edit Prospect</span>
        <button onClick={(e) => setEditModalOpen(false)}><X /></button>
      </DialogTitle>
      <DialogContent>
        <EditPropsectNew
          setEditModalOpen={setEditModalOpen.bind(this)}
          setLoading={setLoading.bind(this)}
          setSnackbarMsg={setSnackbarMsg.bind(this)}
          setSnackbarOpen={setSnackbarOpen.bind(this)}
          prospectData={currentApplicantData}
          updateApplicantData={updateApplicantData.bind(this)}
        />
      </DialogContent>
    </Dialog>

    <Snackbar
      open={snackbarOpen}
      onClose={(e) => setSnackbarOpen(false)}
      TransitionComponent={Slide}
      message={snackbarMsg}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(e) => setSnackbarOpen(false)}
        >
          <X />
        </IconButton>
      }
    />

    <div className="FooterBack">
      <Footer />
    </div>
  </React.Fragment>);
};

export default withAuthenticationRequired(ProspectDetails, {
  onRedirecting: () => <Loader />,
});