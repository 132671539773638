import styles from "../../../../maintenancerequestdetails.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../../../components/AddLogs";
import { Select, FormControl, InputLabel, MenuItem, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const schema = yup.object().shape({
  assignee_id: yup.string().required("This field is Required"),
  date: yup.date().required("This field is Required"),
  time: yup.date().required("This field is Required"),
});

const AppointmentAdd = ({ appEditId, appEditData, requestData, setRequestData, setAddModalOpen, setSnackbarOpen, setSnackbarMsg, setLoading }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [assigneeList, setAssigneeList] = useState([]);

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assignee_id: "",
      date: dayjs(),
      time: dayjs().set("hour", 9).set("minute", 0),
    },
  });

  useEffect(() => {
    if (appEditData) {
      setValue("assignee_id", appEditData?.assignee_id);
      setValue("date", dayjs(appEditData?.datetime));
      setValue("time", dayjs(appEditData?.datetime));
    }
  }, [appEditData, setValue]);

  useEffect(() => {
    async function getAssigneeList() {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      axios.get(process.env.REACT_APP_CASE_API_URL + "v1/technicians", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setAssigneeList(response.data.data);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
    getAssigneeList();
  }, [getAccessTokenSilently]);

  const onSubmit = async (data) => {
    if (data?.date) {
      data = { ...data, date: dayjs(data?.date).format("YYYY-MM-DD") };
    }
    if (data?.time) {
      data = { ...data, time: dayjs(data?.time).format("hh:mm A") };
    }

    setAddModalOpen(false);
    setLoading(true);

    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

    if (appEditId === 0) {
      return axios.post(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointments", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let appointments = [response.data.data].concat(prev?.appointments);
          return { ...prev, appointments: appointments };
        });

        let logData = {
          title: "Maintenance request new appointment is created",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
            "Technician: " + requestData?.data?.assignee_name,
            "Appointment date: " + dayjs(response.data.data?.date).format("L"),
            "Appointment time: " + response.data.data?.time,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    } else {
      axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + requestData?.id + "/appointments/" + appEditId, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setLoading(false);

        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setRequestData((prev) => {
          let appointments = prev?.appointments?.map((i) => {
            if (i?.id === appEditId) {
              return response.data.data;
            }
            return i;
          });
          return { ...prev, appointments: appointments };
        });

        let logData = {
          title: "Maintenance request appointment is updated",
          description: [
            "Name: " + requestData?.title,
            "Request number: " + requestData?.request_no,
            "Property: " + requestData?.property_name,
            "Apartment: " + requestData?.apt_name,
            "Tenant: " + requestData?.tenant_name,
            "Technician: " + requestData?.data?.assignee_name,
            "Appointment date: " + dayjs(response.data.data?.date).format("L"),
            "Appointment time: " + response.data.data?.time,
          ],
        };
        AddLogCallAPI(logData, token);
      }).catch((error) => {
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  return (<div className={`${styles.ModalFormGroup}`}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={`${styles.PopFormGroup}`}>
          <div className={`${styles.FormGroupFull} FormGroup`}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Technician*</InputLabel>
              <Controller
                name="assignee_id"
                control={control}
                render={(field) => (
                  <Select
                    label="Select Technician*"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  >
                    {assigneeList.map((item) => {
                      return (<MenuItem value={item?.value}>{item?.label}</MenuItem>);
                    })}
                  </Select>
                )}
              />
            </FormControl>
            {errors.assignee_id && (<p className={`${styles.ErrorM}`}>{errors.assignee_id.message}</p>)}
          </div>
        </div>
        <div className={`${styles.PopFormGroupTwo}`}>
          <div className={`${styles.Width48}`}>
            <div className={`${styles.DatePick} TimePick`}>
              <Controller
                className={`${styles.formControl}`}
                name="date"
                control={control}
                render={(fields) => (
                  <MobileDatePicker
                    {...fields}
                    label="Select Date"
                    disablePast
                  />
                )}
              />
              {errors.date && (<p className={`${styles.ErrorM}`}>{errors.date.message}</p>)}
            </div>
          </div>
          <div className={`${styles.Width48}`}>
            <div className={`${styles.TimePick} TimePick`}>
              <Controller
                className={`${styles.formControl}`}
                name="time"
                control={control}
                render={(fields) => (
                  <MobileTimePicker {...fields} label="Select Time" />
                )}
              />
              {errors.time && (<p className={`${styles.ErrorM}`}>{errors.time.message}</p>)}
            </div>
          </div>
        </div>
      </LocalizationProvider>
      <div className={`${styles.ButtonAction}`}>
        <Button onClick={(e) => setAddModalOpen(false)} className="CancelPopupBU">Cancel</Button>
        <Button type="submit" className="SubmitPopupBU">{appEditId ? "Update" : "Add"}</Button>
      </div>
    </form>
  </div>);
};

export default AppointmentAdd;