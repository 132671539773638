import { Dialog, DialogContent, DialogTitle, TablePagination, Tooltip } from "@mui/material";
import styles from "../../tenantdetails.module.css";
import { Dropdown } from "react-bootstrap";
import { ArrowLeft, ArrowUp, ChevronDown, Plus, Search, X } from "react-feather";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { toast } from "react-smart-toaster";
import AddRequestForm from "./AddRequestForm";

const OpenedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    className={`${styles.ReferStatusBU} ${styles.Open}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    Open <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));


const InprogressToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    className={`${styles.ReferStatusBU} ${styles.InProgress}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    In-Progress <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const ResolvedToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    className={`${styles.ReferStatusBU} ${styles.Resolved}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    Resolved <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const CancelledToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    className={`${styles.ReferStatusBU} ${styles.Cancelled}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    Cancelled <ChevronDown className={`${styles.DownArrowStatus}`} />
  </button>
));

const RequestTab = ({ tenantData, setTenantData, setLoading, setSnackbarOpen, setSnackbarMsg }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [requestList, setRequestList] = useState([]);
  const [page, setPage] = useState(0);
  const [filterKey, setFilterKey] = useState("");
  const [addRequestPop, setAddRequestPop] = useState(false);
  const [perPage, setPerPage] = useState(5);

  useEffect(() => {
    if (tenantData?.cases) {
      let requestTempList = tenantData?.cases?.filter(i => i?.type === 'maintenance-requests');

      if (filterKey !== "") {
        let inputVal = filterKey.toLowerCase();
        requestTempList = requestTempList.filter((item) => {
          return (item.title.toLowerCase().includes(inputVal) || item.request_no.toLowerCase().includes(inputVal) || item.property_name.toLowerCase().includes(inputVal) || item.apt_name.toLowerCase().includes(inputVal) || item.assignee_name.toLowerCase().includes(inputVal));
        });
      }

      setRequestList(requestTempList);
    }
  }, [tenantData, filterKey]);


  const changeCaseStatus = async (item, newVal) => {
    if (item.current_status !== newVal) {
      setLoading(true);
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

      axios.put(process.env.REACT_APP_CASE_API_URL + "v1/maintenance-requests/" + item.id, { current_status: newVal }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMsg(response.data.message);

        setTenantData(prev => {
          let allCases = prev?.cases.map((i) => {
            if (i.id === item.id)
              return { ...i, current_status: newVal };
            return i;
          });

          return { ...prev, cases: allCases };
        });
      }).catch((error) => {
        setLoading(false);
        if (typeof error.response !== "undefined")
          toast.error(error.response.data.message);
      });
    }
  }

  const onRequestAdd = (data) => {
    data = { ...data, type: 'maintenance-requests' };
    setTenantData(prev => {
      return { ...prev, cases: [data].concat(prev.cases) };
    });
  }

  return <div className={`${styles.MainReqMain}`}>
    <div className={`${styles.NotesSearchArea}`}>
      <div className={`${styles.FilterSearchSec}`}>
        <div className={`${styles.NotesSearchMain}`}>
          <Search />
          <input type="text" placeholder="Search" className={`${styles.searchInput}`} onChange={(e) => setFilterKey(e.target.value)} />
        </div>
        {/* <Dropdown>
                    <Dropdown.Toggle className={`${styles.ActionsBULine} ActionsBULine`} id="dropdown-basic">
                        <span className={`${styles.ReqBigFilter}`}>Show: All <ChevronDown /></span>
                        <span className={`${styles.ReqSmallFilter}`}><Filter /></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                        <FormGroup row className={`${styles.CheckFilter}`}>
                            <FormControlLabel control={<Checkbox name="all" color="primary"
                                checked={caseFilterType?.requests || caseFilterType?.complaints || caseFilterType?.violations}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCaseFilterType({ requests: true, complaints: true, violations: true });
                                    } else {
                                        setCaseFilterType({ requests: false, complaints: false, violations: false });
                                    }
                                }} />} label="All" />
                            <FormControlLabel control={<Checkbox name="requests" color="primary"
                                checked={caseFilterType?.requests}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCaseFilterType(prev => {
                                            return { ...prev, requests: true };
                                        });
                                    } else {
                                        setCaseFilterType(prev => {
                                            return { ...prev, requests: false };
                                        });
                                    }
                                }} />} label={"Maintenance"} />
                            <FormControlLabel control={<Checkbox name="complaints" color="primary"
                                checked={caseFilterType?.complaints}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCaseFilterType(prev => {
                                            return { ...prev, complaints: true };
                                        });
                                    } else {
                                        setCaseFilterType(prev => {
                                            return { ...prev, complaints: false };
                                        });
                                    }
                                }} />} label="Complaint" />
                            <FormControlLabel control={<Checkbox name="violations" color="primary"
                                checked={caseFilterType?.violations}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCaseFilterType(prev => {
                                            return { ...prev, violations: true };
                                        });
                                    } else {
                                        setCaseFilterType(prev => {
                                            return { ...prev, violations: false };
                                        });
                                    }
                                }} />} label="Violation" />
                        </FormGroup>
                    </Dropdown.Menu>
                </Dropdown> */}
      </div>
      <Dropdown>
        <Dropdown.Toggle className={`${styles.ActionsBU} ${styles.Red} ActionsBU Red`} id="dropdown-basic">
          <Plus /> <span className={`${styles.AddBuText}`}>New</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
          <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => setAddRequestPop(true)}>
            <img src="/static/img/toolC.svg" alt="" /> Maintenance
          </Dropdown.Item>
          {/*<Dropdown.Item className={`${styles.EditActionsBUListItem}`}>
                        <img src="/static/img/loudspeakerC.svg" alt="" /> Complaint
                    </Dropdown.Item>
                    <Dropdown.Item className={`${styles.EditActionsBUListItem}`}>
                        <img src="/static/img/warningC.svg" alt="" /> Violation
                    </Dropdown.Item>*/}
        </Dropdown.Menu>
      </Dropdown>
    </div>

    {requestList?.length > 0 && (<div className={`${styles.NotesRow}`}>
      {requestList?.map((item, index) => {
        return (perPage === -1 || (index >= page * perPage && index < page * perPage + perPage)) ? (<Link to={"/cases/maintenance-request/details/" + item.id} key={item?.id}>
          <div className={`${styles.NotesCard}`}>
            <div className={`${styles.TitleWithTag}`}>
              <p className={`${styles.ReqNo}`}>
                {item?.sub_category === "Electrical Socket" && <img src="/static/img/ElectricalSocket.svg" alt="" />}
                {item?.sub_category === "Light Switch/Dimmer" && <img src="/static/img/Dimmer.svg" alt="" />}
                {item?.sub_category === "Leak" && <img src="/static/img/Leak.svg" alt="" />}
                {item?.sub_category === "Drain clogged" && <img src="/static/img/DrainClogged.svg" alt="" />}
                {item?.sub_category === "Water temperature Issue" && <img src="/static/img/WaterTemperature.svg" alt="" />}
                {item?.sub_category === "Stove/Oven Issue" && <img src="/static/img/StoveOven.svg" alt="" />}
                {item?.sub_category === "Refrigerator Issue" && <img src="/static/img/Refrigerator.svg" alt="" />}
                {item?.sub_category === "Washer/Dryer Issue" && <img src="/static/img/Washer.svg" alt="" />}
                {item?.sub_category === "Other Issue" && <img src="/static/img/other.svg" alt="" />}

                #{item.request_no}
              </p>
              <p className={`${styles.InsideConName}`}>
                <Tooltip title="Repair window">
                  <span className={`${styles.ConNameTrancate}`}>{item.title}</span>
                </Tooltip>
              </p>
              {item.overdue && item.current_status !== 3 && (<span className={`${styles.TagDiv} ${styles.Red}`}>{item.overdue}</span>)}
            </div>
            <div className={`${styles.PropeCardInFR}`}>
              <div className={`${styles.MainReqConDivBig}`}>
                <div className={`${styles.MainReqConUR}`}>
                  <Tooltip title={item.tenant_name}>
                    <p className={`${styles.MainReqConDe} ${styles.TextEclipsName}`}>{item.tenant_name}</p>
                  </Tooltip>
                  <p className={`${styles.MainReqConDe}`}>
                    <span>Submitted {item.submitted_date}</span>
                  </p>
                </div>
              </div>
              <div className={`${styles.MainReqConDivSmall}`}>
                <div className={`${styles.MainReqConUR}`}>
                  <p className={`${styles.MainReqConDe}`}>
                    <Tooltip title={item.property_name}>
                      <span className={`${styles.TextEclips}`}>{item.property_name}</span>
                    </Tooltip>
                    - {item.apt_name}
                  </p>
                  <p className={`${styles.MainReqConDe}`}>
                    <span>Technician</span>
                    <Tooltip title={item.assignee_name ? item.assignee_name : "--"}>
                      <span className={`${styles.TextEclipsName}`}>{item.assignee_name ? item.assignee_name : "--"}</span>
                    </Tooltip>
                  </p>
                </div>
              </div>
              <div className={`${styles.MainReqConAction}`}>
                {item.priority === 1 && (<p className={`${styles.ReffStatus} ${styles.Low}`}>
                  <ArrowUp />
                  <span> {item.priority_text}</span>
                </p>)}
                {item.priority === 2 && (<p className={`${styles.ReffStatus} ${styles.Medium}`}>
                  <ArrowUp />
                  <span> {item.priority_text}</span>
                </p>)}
                {item.priority === 3 && (<p className={`${styles.ReffStatus} ${styles.High}`}>
                  <ArrowUp />
                  <span> {item.priority_text}</span>
                </p>
                )}
                <div className={`${styles.MainReqConDropBU}`}>
                  <Dropdown>
                    {item.current_status === 0 && (<Dropdown.Toggle as={OpenedToggle} />)}

                    {item.current_status === 1 && (<Dropdown.Toggle as={InprogressToggle} />)}

                    {item.current_status === 2 && (<Dropdown.Toggle as={ResolvedToggle} />)}

                    {item.current_status === 3 && (<Dropdown.Toggle as={CancelledToggle} />)}

                    <Dropdown.Menu className="DropDownMenuBoxCus">
                      <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeCaseStatus(item, 0); }}>Open</Dropdown.Item>
                      <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeCaseStatus(item, 1); }}>In-Progress</Dropdown.Item>
                      <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeCaseStatus(item, 2); }}>Resolved</Dropdown.Item>
                      <Dropdown.Item className="DropDListWIcon" onClick={(e) => { e.stopPropagation(); changeCaseStatus(item, 3); }}>Cancelled</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </Link>) : null;
      })}

      {requestList.length > 0 && (<div className="Pagination TablePaginationNew">
        <TablePagination
          count={requestList.length}
          color="primary"
          page={page}
          rowsPerPage={perPage}
          onPageChange={(e, v) => setPage(v)}
          rowsPerPageOptions={[
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: 'All', value: -1 },
          ]}
          onRowsPerPageChange={(e) => {
            setPage(0);
            setPerPage(e.target.value);
          }}
          labelRowsPerPage={'Requests per Page:'}
        />
      </div>)}

    </div>)}

    {requestList?.length === 0 && (<div className={`${styles.NotesRow}`}>
      <div className={`${styles.NoDataMain}`}>
        <div>
          <img src="/static/img/no-content.png" alt="" className={`${styles.NoDataIMG}`} />
          <p className={`${styles.NoDataText}`}>No Case Found</p>
        </div>
      </div>
    </div>)}

    {/*<Dialog
      open={addRequestPop}
      onClose={(e) => setAddRequestPop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
      fullWidth
      maxWidth="sm"
    >
      <AddMaintenanceForm closeForm={setAddRequestPop.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onRequestAdd.bind(this)} tenant_id={tenantData?.id} />
      <button onClick={(e) => setAddRequestPop(false)} className={`${styles.CloseAddTenantDash}`}>
        <X />
      </button>
    </Dialog>*/}

    <Dialog
      open={addRequestPop}
      onClose={(e) => setAddRequestPop(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="NewPopUp MobileForm"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        <button className={`${styles.BackAddBU}`} onClick={(e) => setAddRequestPop(false)}><ArrowLeft /></button>
        New Maintenance Request
        <button onClick={(e) => setAddRequestPop(false)}>
          <X />
        </button>
      </DialogTitle>
      <DialogContent className={`${styles.RequestBodyPop}`}>
        <AddRequestForm setLoading={setLoading.bind(this)} setAddRequestPop={setAddRequestPop.bind(this)} setSnackbarOpen={setSnackbarOpen.bind(this)} setSnackbarMsg={setSnackbarMsg.bind(this)} onSubmitLocal={onRequestAdd.bind(this)} tenant_id={tenantData?.id} />
      </DialogContent>
    </Dialog>

  </div>;
}

export default RequestTab;