import styles from "../articles.module.css";
import { Edit, Eye, EyeOff, MoreVertical, Trash2 } from "react-feather";
import { Dropdown } from "react-bootstrap";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        maxWidth: 300,
        fontSize: 13,
        textAlign: 'left',
    },
}));

const ArticleCard = ({ item, setShowDeletePopup, setSelectecId, setShowEditForm, setShowEditPopup, setIsPublished }) => {

    return (<div className={`${styles.ProspectCard}`}>
        <div className={`${styles.ProspectCardUnder}`}>
            <div className={`${styles.ProspectCardHeadSection}`}>
                <Dropdown className={`${styles.ActionLeaseBUSec}`}>
                    <Dropdown.Toggle className={`${styles.ActionsLeaseBU} ActionsBU`} id="dropdown-basic"><MoreVertical /></Dropdown.Toggle>

                    <Dropdown.Menu className={`${styles.ActionsBUList} ActionsBUList`}>
                        <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setShowEditForm(true); setSelectecId(item?.id); }}><Edit /> Edit</Dropdown.Item>
                        {item?.is_published === 1 && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setShowEditPopup(true); setIsPublished(false); setSelectecId(item?.id); }}><EyeOff /> Draft</Dropdown.Item>}
                        {item?.is_published === 0 && <Dropdown.Item className={`${styles.EditActionsBUListItem}`} onClick={(e) => { setShowEditPopup(true); setIsPublished(true); setSelectecId(item?.id); }}><Eye /> Published</Dropdown.Item>}
                        <Dropdown.Item className={`${styles.EditActionsBUListItem} ${styles.Delete}`} onClick={(e) => { setShowDeletePopup(true); setSelectecId(item?.id); }}><Trash2 /> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className={`${styles.ProsIMGSec}`}>
                    <img src="/static/img/article-kb.svg" className={`${styles.ProspectCardImg}`} alt="" />
                </div>
                <div className={`${styles.HeadConSec}`}>
                    <p className={`${styles.ProsUserName}`}>
                        <span className={`${styles.UserName}`}>{item?.title}</span>
                    </p>
                    <p className={`${styles.ProsUserName}`}>{item?.author_name}</p>
                </div>
            </div>
            <div className={`${styles.ProspectCardBodySec}`}>
                <ul>
                    <li>
                        <p className={`${styles.Title}`}>Category:</p>
                        <p className={`${styles.Content}`}>
                            {item?.category_names.slice(0, 2).join(', ')}
                            {item?.category_names?.length > 2 && <BootstrapTooltip title={item?.category_names.join(', ')}>
                                <span className={`${styles.MorePropertyBU}`}>+{item?.category_names?.length - 2}</span>
                            </BootstrapTooltip>}
                        </p>
                    </li>
                    <li>
                        <p className={`${styles.Title}`}>Property:</p>
                        <p className={`${styles.Content}`}>
                            {item?.property_names.slice(0, 2).join(', ')}
                            {item?.property_names?.length > 2 && <BootstrapTooltip title={item?.property_names.join(', ')}>
                                <span className={`${styles.MorePropertyBU}`}>+{item?.property_names?.length - 2}</span>
                            </BootstrapTooltip>}
                        </p>
                    </li>
                </ul>
            </div>
            <div className={`${styles.TemplateCardFoot}`}>
                <p className={`${styles.LeaseConDate}`}>{dayjs(item?.created_at).fromNow()}</p>
                {item?.is_published === 1 && <span className={`${styles.PublishBadge}`}>Published</span>}
                {item?.is_published === 0 && <span className={`${styles.UnublishBadge}`}>Draft</span>}
            </div>
        </div>
    </div>)
}

export default ArticleCard;