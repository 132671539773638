import styles from "../articles.module.css";
import { Box, Button, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { useAuth0 } from "@auth0/auth0-react";
import { AddLogCallAPI } from "../../../../components/AddLogs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const schema = yup.object().shape({
    title: yup.string().required('This field is Required'),
    category_ids: yup.array().required("This field is Required").nullable(),
    property_ids: yup.array().required("This field is Required").nullable(),
    description: yup.string().required("This field is Required"),
    video_url: yup.string().required("This field is Required"),
});

const ArticleAdd = ({ setShowAddForm, setLoading, onAdd, setSnackbarMsg, setSnackbarOpen, isEdit, selectecId, setModalDisplay }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [propertyList, setPropertyList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const { register, handleSubmit, control, errors, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            title: '',
            video_url: '',
            category_ids: [],
            property_ids: [],
            is_published: 0
        }
    });

    useEffect(() => {
        const getPropertyList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/property-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setPropertyList(response.data.data);
            }).catch(() => {
                setPropertyList([]);
            });
        }

        getPropertyList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const getCategoryList = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/article-category-list", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setCategoryList(response.data.data);
            }).catch(() => {
                setCategoryList([]);
            });
        }

        getCategoryList();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const getDetails = async () => {
            const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

            axios(process.env.REACT_APP_RESOURCE_API_URL + "v1/articles/" + selectecId, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let responseData = response.data.data;

                console.log(responseData);
                setValue('title', responseData?.title);
                setValue('video_url', responseData?.video_url);
                setValue('description', responseData?.description);
                setValue('category_ids', responseData?.category_id_arr);
                setValue('property_ids', responseData?.property_id_arr);
                setSelectedCategories(responseData?.category_id_arr);
                setSelectedProperties(responseData?.property_id_arr);
            });
        }

        if (isEdit) {
            getDetails();
        }
    }, [isEdit, selectecId, getAccessTokenSilently, setValue]);

    const get_youtube_thumbnail = (url) => {
        if (url) {
            var video_id, thumbnail, result;
            if (url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/);
                video_id = result.pop();
            } else if (url.match(/youtu.be\/(.{11})/)) {
                result = url.match(/youtu.be\/(.{11})/)
                video_id = result.pop();
            }

            if (video_id) {
                thumbnail = "https://img.youtube.com/vi/" + video_id + "/maxresdefault.jpg";
                return thumbnail;
            }
        }
        return false;
    }

    const onSubmit = async (data) => {
        let postData = { title: data?.title, description: data?.description, video_url: data?.video_url, is_published: data?.is_published };

        if (data?.video_url !== '') {
            let thumbnail_url = get_youtube_thumbnail(data?.video_url);
            postData = { ...postData, thumbnail_url: thumbnail_url };
        }

        if (data?.category_ids?.length) {
            postData = { ...postData, category: data?.category_ids };
        }

        if (data?.property_ids?.length) {
            postData = { ...postData, properties: data?.property_ids };
        }

        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_RESOURCE_API_URL + "v1/articles", postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            onAdd(response.data.data);
            setShowAddForm(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let logData = {
                'title': 'A new article is added',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Categories: ' + response.data.data?.category_names.join(', '),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onUpdate = async (data) => {
        let postData = { title: data?.title, description: data?.description, video_url: data?.video_url, is_published: data?.is_published };

        if (data?.video_url !== '') {
            let thumbnail_url = get_youtube_thumbnail(data?.video_url);
            postData = { ...postData, thumbnail_url: thumbnail_url };
        }

        if (data?.category_ids?.length) {
            postData = { ...postData, category: data?.category_ids };
        }

        if (data?.property_ids?.length) {
            postData = { ...postData, properties: data?.property_ids };
        }

        setLoading(true);
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });

        axios.post(process.env.REACT_APP_RESOURCE_API_URL + "v1/articles/" + selectecId, postData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false);
            onAdd(response.data.data);
            setShowAddForm(false);

            setSnackbarMsg(response.data.message);
            setSnackbarOpen(true);

            let logData = {
                'title': 'An article is updated',
                'description': [
                    'Title: ' + response.data.data?.title,
                    'Categories: ' + response.data.data?.category_names.join(', '),
                    'Properties: ' + response.data.data?.property_names.join(', ')
                ]
            }
            AddLogCallAPI(logData, token);
        }).catch((error) => {
            if (typeof error.response !== "undefined")
                toast.error(error.response.data.message);
            setLoading(false);
        });
    }

    return (<div className={`${styles.ModalFormGroup}`}>
        <form onSubmit={handleSubmit(isEdit ? onUpdate : onSubmit)}>
            <input type="hidden" {...register('is_published')} />
            <div className={`${styles.AddLeaseSection}`}>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <Controller
                            name={`title`}
                            control={control}
                            render={(field) => (<TextField {...field} id="outlined-basic" label="Alert Title*" variant="outlined" />)}
                        />
                        {errors.title && (<p className={`${styles.ErrorM}`}>{errors?.title?.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Category*</InputLabel>
                            <Controller
                                name="category_ids"
                                control={control}
                                render={(field) => (
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={selectedCategories}
                                        onChange={(e) => {
                                            setSelectedCategories(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
                                            setValue("category_ids", e.target.value);
                                        }}
                                        input={<OutlinedInput id="select-multiple-chip" label="Category*" />}
                                        renderValue={(selected) => {
                                            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {categoryList.map((value) => {
                                                    return selected?.indexOf(value?.id) > -1 ? <Chip key={value?.id} label={value?.title} /> : null;
                                                })}
                                            </Box>
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {categoryList.map((item) => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                <Checkbox checked={selectedCategories.indexOf(item.id) > -1} />
                                                <ListItemText primary={item.title} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        {errors.category_ids && (<p className={`${styles.ErrorM}`}>{errors.category_ids.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Property*</InputLabel>
                            <Controller
                                name="property_ids"
                                control={control}
                                render={(field) => (
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={selectedProperties}
                                        onChange={(e) => {
                                            setSelectedProperties(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
                                            setValue("property_ids", e.target.value);
                                        }}
                                        input={<OutlinedInput id="select-multiple-chip" label="Property*" />}
                                        renderValue={(selected) => {
                                            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {propertyList.map((value) => {
                                                    return selected?.indexOf(value?.id) > -1 ? <Chip key={value?.id} label={value?.name} /> : null;
                                                })}
                                            </Box>
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {propertyList.map((item) => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                <Checkbox checked={selectedProperties.indexOf(item.id) > -1} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        {errors.property_ids && (<p className={`${styles.ErrorM}`}>{errors.property_ids.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.FormGroupTextareaFull}`}>
                    <label className={`${styles.FilterLabel}`}>Description<span className={`${styles.errorSpan}`}>*</span></label>
                    <Controller
                        name="description"
                        control={control}
                        render={(field) => (
                            <Editor
                                className={`${styles.formControlTextArea}`}
                                apiKey={"z3r7z5rrb92qgt55caemf3mrz1kdxuckuq25g9u3syc75r7u"}
                                value={field?.value}
                                init={{
                                    height: 400,
                                    menubar: false,
                                    plugins: "lists media mediaembed",
                                    toolbar: "undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify |  numlist bullist | forecolor backcolor removeformat |  fullscreen  | ltr rtl | media",
                                    init_instance_callback: (editor) => {
                                        editor.on('ExecCommand', (e) => {
                                            console.log(`The ${e.command} command was fired.`);
                                            if (e.command === 'mceMedia') {
                                                setModalDisplay(false);
                                            } else {
                                                setModalDisplay(true);
                                            }
                                        });
                                    }
                                }}
                                onEditorChange={(e) => field.onChange(e)}
                            />
                        )}
                    />
                    {errors.description && (<p className={`${styles.ErrorM}`}>{errors.description.message}</p>)}
                </div>
                <div className={`${styles.AddDocTitleSec}`}>
                    <div className={`${styles.FormGroupAddLease} FormGroup`}>
                        <Controller
                            name={`video_url`}
                            control={control}
                            render={(field) => (<TextField {...field} id="outlined-basic" label="Video URL for Thumbnail*" variant="outlined" />)}
                        />
                        {errors.video_url && (<p className={`${styles.ErrorM}`}>{errors?.video_url?.message}</p>)}
                    </div>
                </div>
                <div className={`${styles.ButtonAction}`}>
                    <Button className="CancelPopupBU" onClick={(e) => setShowAddForm(false)}>Cancel</Button>
                    <Button className="SubmitPopupBU" type="submit" onClick={(e) => setValue('is_published', 0)}>{isEdit ? 'Update' : 'Save'}</Button>
                    <Button className="SubmitPopupBU" type="submit" onClick={(e) => setValue('is_published', 1)}>{isEdit ? 'Update & Publish' : 'Save & Publish'}</Button>
                </div>
            </div>
        </form>
    </div>);
}

export default ArticleAdd;