import styles from "../article-category.module.css";
import { Plus, Search } from "react-feather";

const ArticleCategoryFilter = ({ setFilterData, setShowAddForm }) => {

    return (<div className={`${styles.SearchFilterSec}`}>
        <div className={`${styles.SearchCon}`}>
            <div className={`${styles.SearchConSec}`}>
                <Search />
                <input type="text" placeholder="Search" onChange={(e) => setFilterData(prev => { return { ...prev, searchKey: e.target.value } })} />
            </div>
        </div>

        <div className={`${styles.FilterSec}`}>
            <button className={`${styles.AddNoteBu}`} onClick={(e) => setShowAddForm(true)}><Plus /> <span>Category</span></button>
        </div>
    </div >)
}

export default ArticleCategoryFilter;